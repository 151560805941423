import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { createBrowserHistory } from "history";

import Services from './Service'
import Features from "./Features";
import Marketing from "./Marketing"
import Pricing from "./Pricing"
import Overview from "./Overview"
import Contactus from "./Contactus"
import FAQ from './Faq'
import Terms from './Terms'
import Store from './Store'
import privacypolicy from './privacyPolicy'

// import createHistory from "history/createBrowserHistory";
const history = createBrowserHistory();

ReactDOM.render(
  <Router history={history}>

    <Route exact path="/" component={App}></Route>
    <Route exact path="/features" component={Features}></Route>
    <Route exact path="/services" component={Services}></Route>
    <Route exact path="/marketing" component={Marketing}></Route>
    <Route exact path="/pricing" component={Pricing}></Route>

    <Route exact path="/overview" component={Overview}></Route>
    <Route exact path="/contactus" component={Contactus}></Route>
    <Route exact path="/faq" component={FAQ}></Route>
    <Route exact path="/terms-of-service" component={Terms}></Route>

    <Route exact path="/store" component={Store}></Route>
    <Route exact path="/privacypolicy" component={privacypolicy}></Route>




  </Router>,

  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

