
import Header from './Header'
import Service from './Service'
import Footer from './Footer'
import Home from './Home'

function App() {
  return (
    <div className="App">
      <Header />
      <Home />
      <Footer />
    </div>
  );
}

export default App;
