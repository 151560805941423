//  var urlString = "http://api.globaltrendz.info/";
// var urlString = "http://localhost:8052/";
var urlString = "https://api.bechofy.in/";

const GetApiCall = {
	getRequest(url) {
		// console.log(urlString+url)
		return fetch(urlString + url, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Headers": "*",
				"Content-Type": "application/json",
			},
		});
	},
};

export default GetApiCall;
