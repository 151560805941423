import React from 'react';

import Header from './Header'

import Footer from './Footer'

import { Helmet } from "react-helmet";



class Features extends React.Component {
    render() {
        return (
            <>
                <Helmet
                    title="Bechofy provides everything you need to manage your store."
                    meta={[
                        {
                            property: "description",
                            content: "Bechofy offers Web Hosting & Security, Online Web Store, Delivery Integrations, Content Management, Marketing & Promotions, Dashboards & Reporting & Support."
                        }
                    ]}
                />
                <Header />
                <div class="main">

                    {/* <!--hero section start--> */}
                    <section class="ptb-25  gradient-bg ">
                        <div class="container">
                            <div class="row align-items-center">
                                <div class="col-md-12 col-lg-7">
                                    <div class="hero-slider-content hero-slider-content1 pt-5">
                                        <strong>Bechofy Features</strong>
                                        <h1 class="">All that you need to start and run your online business</h1>
                                        <p class="lead">Having an online store that can be managed very easily is the key to a successful business.</p>

                                        <div class="action-btns mt-3">
                                            <a href="/store" class="btn secondary-solid-btn">Start your Store</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-lg-5">
                                    <div class="image-wrap pt-10">
                                        <img src="/img/hero-img/Online-business.png" class="img-fluid" alt="hero" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* <!--hero section end--> */}

                    <section class="promo-section ptb-100 feature">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-md-10 col-lg-12">
                                    <div class="section-heading text-center">

                                        <h2>Bechofy gives you all that you need to manage your store.</h2>
                                        <span class="animate-border mr-auto ml-auto mb-4"></span>
                                        <p class="lead">A list of features that make your selling easy!</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row justify-content-center">
                                <div class="col-md-6 col-lg-4">
                                    <div class="promo-single-wrap p-4   custom-shadow  rounded">
                                        <div class="promo-icon mb-4 text-center">
                                            <img src="/img/Feature-icon/web-hosting-&-security.png" alt="feature-icon" width="65" />
                                            <span class="number-bg">01</span>
                                        </div>
                                        <div class="promo-info">

                                            <h4>Web Hosting & Security</h4>
                                            <ul class="check-list-info">
                                                <li>Custom Domain Name</li>
                                                <li>Secure Web Hosting of the Store</li>
                                                <li>SSL (WildCard SSL)</li>
                                                <li>Data Encryption</li>
                                                <li>Data Storage</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-4">
                                    <div class="promo-single-wrap p-4  custom-shadow rounded">
                                        <div class="promo-icon mb-4 text-center">
                                            <img src="/img/Feature-icon/online-web-store.png" alt="feature-icon" width="65" />
                                            <span class="number-bg">02</span>
                                        </div>
                                        <div class="promo-info">

                                            <h4>Online Web Store</h4>
                                            <ul class="check-list-info">
                                                <li>Full Customized eCommerce Store</li>
                                                <li>Mobile Commerce Ready</li>
                                                <li>Customized Banner</li>
                                                <li>Shopping Cart </li>
                                                <li>Checkout</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-4">
                                    <div class="promo-single-wrap p-4  custom-shadow rounded">
                                        <div class="promo-icon mb-4 text-center">
                                            <img src="/img/Feature-icon/customer-panel.png" alt="feature-icon" width="65" />
                                            <span class="number-bg">03</span>
                                        </div>
                                        <div class="promo-info">

                                            <h4>Customer Panel</h4>
                                            <ul class="check-list-info">
                                                <li>Dashboard </li>
                                                <li>Shopping Analytics</li>
                                                <li>Address Box</li>
                                                <li>Order History & Tracking</li>
                                                <li>Returns & Refund Requests</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-4">
                                    <div class="promo-single-wrap p-4  custom-shadow rounded">
                                        <div class="promo-icon mb-4 text-center">
                                            <img src="/img/Feature-icon/product-management.png" alt="feature-icon" width="65" />
                                            <span class="number-bg">04</span>
                                        </div>
                                        <div class="promo-info">

                                            <h4>Product Management</h4>
                                            <ul class="check-list-info">
                                                <li>Brands</li>
                                                <li>Product Verticals & Categories</li>
                                                <li>Product & Variant Management</li>
                                                <li>Pricing Management</li>
                                                <li>Inventory & Stock Management</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-4">
                                    <div class="promo-single-wrap p-4  custom-shadow rounded">
                                        <div class="promo-icon mb-4 text-center">
                                            <img src="/img/Feature-icon/manage-customer.png" alt="feature-icon" width="65" />

                                            <span class="number-bg">05</span>
                                        </div>
                                        <div class="promo-info">

                                            <h4>Manage Customers</h4>
                                            <ul class="check-list-info">
                                                <li>Customer Accounts</li>
                                                <li>Customer Profiles</li>
                                                <li>Customer Management System</li>
                                                <li>Customer Order History </li>
                                                <li>  Customer Support </li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-4">
                                    <div class="promo-single-wrap p-4  custom-shadow rounded">
                                        <div class="promo-icon mb-4 text-center">
                                            <img src="/img/Feature-icon/order-processing.png" alt="feature-icon" width="65" />

                                            <span class="number-bg">06</span>
                                        </div>
                                        <div class="promo-info">

                                            <h4>Order Processing</h4>
                                            <ul class="check-list-info">
                                                <li>Order Approvals</li>
                                                <li>Order Fulfillment</li>
                                                <li>Returns Managements</li>
                                                <li>Returns Management</li>
                                                <li>  Sales Reports</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-4">
                                    <div class="promo-single-wrap p-4  custom-shadow rounded">
                                        <div class="promo-icon mb-4 text-center">
                                            <img src="/img/Feature-icon/payment-&-ammount.png" alt="feature-icon" width="65" />
                                            <span class="number-bg">07</span>
                                        </div>
                                        <div class="promo-info">

                                            <h4>Payments & Accounts</h4>
                                            <ul class="check-list-info">
                                                <li>Taxations</li>
                                                <li>Invoicing</li>
                                                <li>Payment Gateway Integration</li>
                                                <li>Refunds</li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-4">
                                    <div class="promo-single-wrap p-4  custom-shadow rounded">
                                        <div class="promo-icon mb-4 text-center">
                                            <img src="/img/Feature-icon/deivery-integration.png" alt="feature-icon" width="65" />

                                            <span class="number-bg">08</span>
                                        </div>
                                        <div class="promo-info">

                                            <h4>Delivery Integrations</h4>
                                            <ul class="check-list-info">
                                                <li>Dispatch Management</li>
                                                <li>Logistics Integration</li>
                                                <li>Order Tracking Updation</li>
                                                <li>Order Return Integration</li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-4">
                                    <div class="promo-single-wrap p-4  custom-shadow rounded">
                                        <div class="promo-icon mb-4 text-center">
                                            <img src="/img/Feature-icon/content-managment.png" alt="feature-icon" width="65" />

                                            <span class="number-bg">09</span>
                                        </div>
                                        <div class="promo-info">

                                            <h4>Content Management</h4>
                                            <ul class="check-list-info">
                                                <li>Content Management System</li>
                                                <li>Banner & Advertisements</li>
                                                <li>Pricing Management</li>
                                                <li> Stock Availability </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-4">
                                    <div class="promo-single-wrap p-4  custom-shadow rounded">
                                        <div class="promo-icon mb-4 text-center">
                                            <img src="/img/Feature-icon/marketing-&-promotions.png" alt="feature-icon" width="65" />

                                            <span class="number-bg">10</span>
                                        </div>
                                        <div class="promo-info">

                                            <h4>Marketing & Promotions</h4>
                                            <ul class="check-list-info">
                                                <li>SEO Tags & SEO Optimised</li>
                                                <li>Offers & Promotion setup</li>
                                                <li>Email Marketing</li>
                                                <li>SMS Marketing</li>
                                                <li>Marketing Tools</li>
                                                <li>Google Analytics</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-4">
                                    <div class="promo-single-wrap p-4  custom-shadow rounded">
                                        <div class="promo-icon mb-4 text-center">
                                            <img src="/img/Feature-icon/dashboard-&-reporting.png" alt="feature-icon" width="65" />
                                            <span class="number-bg">11</span>
                                        </div>
                                        <div class="promo-info">

                                            <h4>Dashboards & Reporting</h4>
                                            <ul class="check-list-info">
                                                <li>Dashboard Analytics</li>
                                                <li>Mobile Dashboard</li>
                                                <li>Product Reports</li>
                                                <li>Traffic Report</li>
                                                <li>Sales Reports</li>
                                                <li>Customer Reports</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-4">
                                    <div class="promo-single-wrap p-4  custom-shadow rounded">
                                        <div class="promo-icon mb-4 text-center">
                                            <img src="/img/Feature-icon/support.png" alt="feature-icon" width="65" />
                                            <span class="number-bg">12</span>
                                        </div>
                                        <div class="promo-info">

                                            <h4>Support</h4>
                                            <ul class="check-list-info">
                                                <li>Call Support</li>
                                                <li>Mail Support</li>
                                                <li>Chat Support</li>
                                                <li>Whatsapp Support</li>
                                                <li>Ticket Based Support</li>
                                                <li>Dedicated Account Manager</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section class="call-to-action ptb-25" style={{ background: 'url("img/cta-bg.jpg")no-repeat center center / cover' }}>
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-md-10 col-lg-9">
                                    <div class="section-heading text-center text-white ">
                                        <h2 class="text-white">Take your business to the next Level</h2>
                                        <p class="lead">Setup your Online Business using Bechofy</p>
                                        <a href="/store" class="btn primary-solid-btn ">Start your Store</a>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </section>


                    <section class="why-choose-us ptb-100">
                        <div class="container">
                            <div class="row align-items-center justify-content-between">
                                <div class="col-md-6 col-lg-6">
                                    <div class="why-choose-us-wrap">
                                        <strong class="color-secondary"></strong>
                                        <h3>Choose Bechofy to be your Trusted Ecommerce Partner</h3>
                                        <span class="animate-border mb-4"></span>
                                        <p>All that you need to run your online business is packaged carefully on Bechofy to give you a full fledged Ecommerce Platform</p>

                                        <div class="skill-content-wrap">
                                            <div class="progress-item">
                                                <p class="text-justify">Starting your Online venture with Bechofy will help you see a rewarding future for your business. Bechofy’s comprehensive tools and backend application helps you to manage your online business with ease. Real time data and reports give you proper feedback to see how you can constantly improve your business. A secure hosting platform ensures 99.9%* uptime for your Online Store.</p><p class="font-italic">* Refer to our <a href="term-&-condition.html">Terms & Conditions</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6">
                                    <div class="image-wrap">
                                        <img src="img/ecommerce-partner.png" alt="why choose us" class="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer />

            </>
        )
    }
}
export default Features;