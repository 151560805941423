import React from 'react';

import Header from './Header'

import Footer from './Footer'
import Notiflix from "notiflix";

import PostApiCall from './Api'

import { Helmet } from "react-helmet";


var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
var SecurityCaptch = ''
for (var i = 0; i < 6; i++) {
    SecurityCaptch += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
}

class ContactUs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Name: '',
            Email: '',
            CompanyName: '',
            Mobile: '',
            Message: '',

            NumRegex: /^[0-9]*$/,
            EmailRegex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            Website: '',
            SecurityCode: ''

        };
    }



    sendMessage = () => {

        if (this.state.Name != '') {
            if (this.state.CompanyName != '') {
                if (this.state.Email != '') {
                    if (this.state.EmailRegex.test(this.state.Email)) {
                        if (this.state.Mobile != '') {
                            if (this.state.Mobile.length == 10) {
                                if (this.state.SecurityCode != '') {
                                    if (SecurityCaptch == this.state.SecurityCode) {
                                        this.onPostSave()
                                    }
                                    else {
                                        Notiflix.Notify.failure('security code is not valid');
                                    }

                                }
                                else {
                                    Notiflix.Notify.failure('Please enter security code');
                                }


                            }
                            else {
                                Notiflix.Notify.failure('Please enter valid mobile number');
                            }


                        }
                        else {
                            Notiflix.Notify.failure("Please enter your mobile number");
                        }
                    }
                    else {
                        Notiflix.Notify.failure("Please enter valid email");
                    }

                }
                else {
                    Notiflix.Notify.failure("Please select your email address");
                }

            }
            else {
                Notiflix.Notify.failure("Please enter your company name");
            }
        }
        else {
            Notiflix.Notify.failure("Please enter your name");
        }
    }

    onPostSave = () => {
        Notiflix.Loading.dots("Please wait...");

        PostApiCall.postRequest(
            {
                enquiryid: null,
                companyname: this.state.CompanyName,
                mobilenumber: this.state.Mobile,
                emailaddress: this.state.Email,
                country: null,
                state: null,
                city: null,
                websiteaddress: null,
                industry: null,
                customerid: null,
                agentname: null,
                enquirytype: 'From Contact us Page',
                updated_by: null,
                message: this.state.Message,
                name: this.state.Name,
                subject: null,
            },
            "AddEnquiry"
        ).then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    this.onPostMailer()

                }
                else {
                    Notiflix.Loading.remove();
                    Notiflix.Notify.failure(obj.data);

                }
            }
            ))
    }

    onPostMailer = () => {
        Notiflix.Loading.dots("Please wait...");
        PostApiCall.postRequest(
            {

                companyName: this.state.CompanyName,
                phone: this.state.Mobile,
                email: this.state.Email,
                name: this.state.Name,
                message: this.state.Message
            },
            "EnquiryMailer"
        ).then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    Notiflix.Loading.remove();

                    Notiflix.Notify.success("Your enquiry is successfully sent, our team will get back to you shortly. Thanks! ");

                }
                else {
                    Notiflix.Loading.remove();
                    Notiflix.Notify.failure(obj.data);

                }
            }
            ))

    }


    render() {
        return (
            <>

                <Helmet
                    title="Contact Us - Our ECommerce specialists to take your business online."
                    meta={[
                        {
                            property: "description",
                            content: "Call, Mail, or Whatsapp us to discuss your business and requirements. Our team of experts will be glad to help you with the right solution tailor-made to your requirements."
                        }
                    ]}
                />
                <Header />
                <div class="main">


                    <section class="ptb-25  gradient-bg ">
                        <div class="container">
                            <div class="row align-items-center">
                                <div class="col-md-12 col-lg-7">
                                    <div class="hero-slider-content hero-slider-content1  pt-5">
                                        <strong>Bechofy Contact Us</strong>
                                        <h1 class="">Contact </h1>
                                        <p class="lead">Talk to our ecommerce specialists to take your business online.</p>

                                        <div class="action-btns mt-3">
                                            <a href="/store" class="btn secondary-solid-btn">Start your Store</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-lg-5">
                                    <div class="image-wrap pt-10">
                                        <img src="img/hero-img/contact.png" class="img-fluid" alt="hero" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section class="contact-us-promo ptb-100">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-md-10 col-lg-12">
                                    <div class="section-heading text-center">

                                        <h2>Get In Touch</h2>
                                        <span class="animate-border mr-auto ml-auto mb-4"></span>
                                        <p class="lead">You can Call, Mail or Whatsapp us to discuss your business and requirements. Our team of experts will be glad to help you with the right solution tailor made to your requirements.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-4" >
                                <div class="col-lg-3 col-md-6 col-sm-6">
                                    <a href="tel:+91 79 8282 8789">
                                        <div class="card single-promo-card single-promo-hover text-center shadow-sm">
                                            <div class="card-body py-5">
                                                <div class="pb-2">
                                                    <span class="fas fa-mobile-alt icon-sm color-secondary"></span>
                                                </div>
                                                <div><h5 class="mb-0">Call On Mobile</h5>
                                                    <p class="text-muted mb-0">+91 79 8282 8789</p>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div class="col-lg-3 col-md-6 col-sm-6">
                                    <a href="tel:+91 11 4703 7722">
                                        <div class="card single-promo-card single-promo-hover text-center shadow-sm">
                                            <div class="card-body py-5">
                                                <div class="pb-2">
                                                    <span class="fas fa-phone-alt icon-sm color-secondary"></span>
                                                </div>
                                                <div><h5 class="mb-0">Call On Phone</h5>
                                                    <p class="text-muted mb-0">+91 11 4703 7722</p></div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div class="col-lg-3 col-md-6 col-sm-6">
                                    <a href="mailto:wecare@bechofy.in">
                                        <div class="card single-promo-card single-promo-hover text-center shadow-sm">
                                            <div class="card-body py-5">
                                                <div class="pb-2">
                                                    <span class="fas fa-envelope icon-sm color-secondary"></span>
                                                </div>
                                                <div><h5 class="mb-0">Mail Us</h5>
                                                    <p class="text-muted mb-0">wecare@bechofy.in</p></div>
                                            </div>
                                        </div></a>
                                </div>
                                <div class="col-lg-3 col-md-6 col-sm-6">
                                    <div class="card single-promo-card single-promo-hover text-center shadow-sm">
                                        <div class="card-body py-5">
                                            <div class="pb-2">
                                                <span class=" fas fa-headphones-alt icon-sm color-secondary"></span>
                                            </div>
                                            <div><h5 class="mb-0">Live Chat</h5>
                                                <p class="text-muted mb-0">Chat with Us 24/7</p></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section class="contact-us-section ptb-100">
                        <div class="container contact">
                            <div class="col-12 pb-3 message-box d-none">
                                <div class="alert alert-danger"></div>
                            </div>
                            <div class="row justify-content-around">
                                <div class="col-md-6">
                                    <div class="contact-us-form  custom-shadow  rounded p-5">
                                        <h4>Ready to get started?</h4>
                                        <div class="form-row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <input type="text" class="form-control"
                                                        name="name" placeholder="Your name"
                                                        value={this.state.Name}
                                                        onChange={(name) => {
                                                            this.setState({
                                                                Name: name.target.value
                                                            })

                                                        }}
                                                    />

                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <input type="text" class="form-control"
                                                        name="name" placeholder="Company name"
                                                        value={this.state.CompanyName}
                                                        onChange={(text) => {
                                                            this.setState({
                                                                CompanyName: text.target.value
                                                            })
                                                        }} /> </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <input type="text" class="form-control" placeholder="Contact number"
                                                        value={this.state.Mobile}
                                                        onChange={(mobile) => {

                                                            if ((this.state.NumRegex.test(mobile.target.value)) && (mobile.target.value.length <= 10)) {
                                                                this.setState({

                                                                    Mobile: mobile.target.value
                                                                })
                                                            }

                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <input type="text" className="form-control" placeholder="Email Address"
                                                        value={this.state.Email}
                                                        onChange={(text) => {
                                                            this.setState({
                                                                Email: text.target.value
                                                            })
                                                        }}

                                                    />
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <textarea name="message" id="message" class="form-control" rows="5" cols="25" placeholder="Message"
                                                        value={this.state.Message}
                                                        onChange={(text) => {
                                                            this.setState({
                                                                Message: text.target.value
                                                            })
                                                        }}

                                                    ></textarea>
                                                </div>
                                            </div>

                                            <div class="col-5">
                                                <div class="form-group">
                                                    <h4 className="captchaText">{SecurityCaptch}</h4>

                                                </div>
                                            </div>
                                            <div class="col-7">
                                                <div class="form-group">
                                                    <input type="text" className="form-control" placeholder="Enter Security Code"
                                                        value={this.state.SecurityCode}
                                                        onChange={(text) => {
                                                            this.setState({
                                                                SecurityCode: text.target.value
                                                            })

                                                        }}

                                                    />
                                                </div>
                                            </div>
                                            <div class="col-sm-12 mt-3">
                                                <button type="submit" class="btn primary-solid-btn" id="btnContactUs"
                                                    onClick={this.sendMessage}>
                                                    Send Message
                                                </button>
                                            </div>
                                        </div>



                                        {/* <div class="promo-bottom-shape">
                                            <img src="img/promo-box-shape-bottom.png" alt="shape" />
                                        </div> */}
                                    </div>
                                </div>
                                <div class="col-md-5">
                                    <div class="contact-us-content">
                                        <h2>Have an excellent business idea or an existing business?</h2>
                                        <p class="lead">Take your business online and explore a larger market space. Talk to us and our experts will guide you through as to how you can have a strong online presence.</p>

                                        <a href="/store" class="btn primary-solid-btn align-items-center">Start your Store<span class="ti-arrow-right pl-2"></span></a>

                                        <hr class="my-5" />

                                        <h5>GET IN TOUCH</h5>
                                        <span>Phone: <a href="tel:+91 79 8282 8789">+91 79 8282 8789</a></span>
                                        <br />
                                        <span>Telephone: <a href="tel:+91 11 4703 7722">+91 11 4703 7722</a></span> <br />
                                        <span>Email: <a href="mailto:wecare@bechofy.in" class="link-color">wecare@bechofy.in</a></span>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>



                </div>
                <Footer />

            </>
        )
    }
}
export default ContactUs;