import React from 'react';

import Header from './Header'

import Footer from './Footer'

import { Helmet } from "react-helmet";



class Marketing extends React.Component {
    render() {
        return (
            <>
                <Helmet
                    title="Bechofy India, you can create your own online store today"
                    meta={[
                        {
                            property: "description",
                            content: "Bechofy is an easy-to-use online store builder trusted by many businesses. You can create a fully customizable store with a secure shopping cart. Sign up for a free trial today."
                        }
                    ]}
                />

                <Header />
                <div class="main">

                    <section class="ptb-25  gradient-bg">
                        <div class="container">
                            <div class="row align-items-center">
                                <div class="col-md-12 col-lg-7">
                                    <div class="hero-slider-content hero-slider-content1 pt-5">
                                        <strong>Bechofy Store</strong>
                                        <h1 class="">The simplest way to Sell your Products Online</h1>
                                        <p class="lead">When you have your own store to sell your products online you can create your brand awareness with more value.</p>

                                        <div class="action-btns mt-3">
                                            <a href="/store" class="btn secondary-solid-btn">Start your Store</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-lg-5">
                                    <div class="image-wrap pt-10">
                                        <img src="/img/hero-img/ecommerce-store.png" class="img-fluid" alt="hero" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>


                    <section class="promo-section ptb-100">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-md-10 col-lg-12">
                                    <div class="section-heading text-center">

                                        <h2>Bechofy makes your migration to online selling easy</h2>
                                        <span class="animate-border mr-auto ml-auto mb-4"></span>
                                        <p class="lead">We help you in setting up a fully customized store.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row justify-content-center">
                                <div class="col-md-6 col-lg-6">
                                    <div class="promo-single-wrap p-5 text-center  custom-shadow  rounded">
                                        <div class="promo-icon mb-4">
                                            <img src="img/overview/customised-online-store.png" alt="overview" width="80" />
                                            <span class="number-bg">01</span>
                                        </div>
                                        <div class="promo-info">

                                            <h4>Customized Online Store</h4>
                                            <p>At Bechofy we believe in having a Customized version of your store rather than template based designs. A customized approach to your store helps in setting up the products and layout the way you want it. You can also be free to add content the way you want it. </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6">
                                    <div class="promo-single-wrap p-5 text-center  custom-shadow  rounded">
                                        <div class="promo-icon mb-4">
                                            <img src="img/overview/fully-responsive.png" alt="overview" width="80" />
                                            <span class="number-bg">02</span>
                                        </div>
                                        <div class="promo-info">

                                            <h4>Full Responsive Design</h4>
                                            <p>Our designs are fully Responsive to adapt to Desktops, Mobile Phones and Tablets for easy use on these devices. Customers get a very nice user experience when they would browse your online store. Device adaptability also helps in marketing through search engines.</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>



                    <section class="about-us-section ptb-100 gray-light-bg">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-6 col-lg-6">
                                    <div class="about-us-img">
                                        <img src="img/overview/grow-your-business.png" alt="about us" class="img-fluid" />

                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6">
                                    <div class="about-us-content-wrap">
                                        <strong class="color-secondary"></strong>
                                        <h3>Tools that help you grow your business</h3>
                                        <span class="animate-border mb-4"></span>
                                        <p>Bechofy gives you handy tools to see your growth in business and to forecast business growth. </p>
                                        <ul class="list-unstyled tech-feature-list">
                                            <li class="py-1"><span class="ti-control-forward mr-2 color-secondary"></span><strong>Extensive Dashboard</strong><br />
                                                Bechofy’s Dashboard helps you in understanding how your business is performing.You can get live information about your sales, orders and how your products are performing in terms of sales.
                                            </li>
                                            <li class="py-1"><span class="ti-control-forward mr-2 color-secondary"></span><strong>Promotion Optimized</strong><br />
                                                The whole Web Store is perfectly optimized for SEO and you can manage the complete SEO from a backend tool to work on keywords to get your site crawled on search engines.This can lead to more traffic for your Store.
                                            </li>

                                        </ul>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>




                    <section class="why-choose-us ptb-100">
                        <div class="container">
                            <div class="row align-items-center justify-content-between">
                                <div class="col-md-6 col-lg-6">
                                    <div class="why-choose-us-wrap">
                                        <strong class="color-secondary"></strong>
                                        <h3>Manage your online store with ease</h3>
                                        <span class="animate-border mb-4"></span>
                                        <p>The ability to manage your online store easily takes away most of the worries.</p>

                                        <div class="skill-content-wrap">
                                            <div class="progress-item">
                                                <p>When you use Bechofy you get to manage your store in ease. The comprehensive product management tool gives you the ability to optimize your content for every product in your store. You can handle customers of your online store and process their orders. Even promotions on the store can be managed through the Bechofy Platform</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6">
                                    <div class="image-wrap">
                                        <img src="img/why-choose-us.png" alt="why choose us" class="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>
                <Footer />

            </>
        )
    }
}
export default Marketing;