import React from 'react';

import Header from './Header'

import Footer from './Footer'

import { Helmet } from "react-helmet";



class Pricing extends React.Component {
    render() {
        return (
            <>
                <Helmet
                    title="Setup and Open Your Online Store With Bechofy Today - Free Trial"
                    meta={[
                        {
                            property: "description",
                            content: "Create your store by choosing the best plan for you."
                        }
                    ]}
                />
                <Header />

                <div class="main">

                    <section class="ptb-25  gradient-bg ">
                        <div class="container">
                            <div class="row align-items-center">
                                <div class="col-md-12 col-lg-7">
                                    <div class="hero-slider-content hero-slider-content1 pt-5">
                                        <strong>Bechofy Pricing Solutions</strong>
                                        <h1 class="">Select the plan you wish to work with</h1>
                                        <p class="lead">Bechofy has options for customers to choose according to their needs.</p>

                                        <div class="action-btns mt-3">
                                            <a href="/store" class="btn secondary-solid-btn">Start your Store</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-lg-5">
                                    <div class="image-wrap pt-10">
                                        <img src="img/hero-img/pricing-page.png" class="img-fluid" alt="hero" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section class="pricing-section ptb-100">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-md-9 col-lg-10">
                                    <div class="section-heading text-center mb-5">

                                        <h2>Bechofy’s flexible pricing plans help you to get online instantly</h2>
                                        <span class="animate-border mr-auto ml-auto mb-4"></span>
                                        <p class="lead">
                                            Looking at the market trends we have carefully worked out our pricing plan to fit all kinds of businesses. Our flexible pricing policy gives you an easy approach to start your online Ecommerce venture as soon as possible.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="row align-items-center justify-content-between">
                                <div class="col-12">
                                    <div class="switch-wrap text-center mb-5">
                                        <p><strong class="color-secondary">Pricing Plans</strong></p>

                                    </div>
                                </div>


                                {/* <div class="col-lg-4 col-md-4 col-12">
                                    <div class="card  single-pricing-pack">
                                        <div class="price-name text-center">
                                            <h5 class="py-4 mb-0">Quaterly Plan</h5>
                                        </div>
                                        <div class="card-header py-1 border-0 pricing-header">
                                            <div class="price text-center mb-0 monthly-price">Rs. 8,500/- Per Month</div>
                                        </div>

                                        <div class="card-body">
                                            <ul class="list-unstyled mb-4 pricing-feature-list">
                                                <li><b>Domain & Hosting</b></li>
                                                <li>Custom Domain<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Secure Store Web Hosting<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Google Workspace Emails<span>Optional</span></li>
                                                <li><b>Vendors</b></li>
                                                <li>Single Vendor<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Multi Vendor (Prices as per Customized Requirement)<span>Optional</span></li>
                                                <li><b>Store Front</b></li>
                                                <li>Online Store Includes ecommerce website and blog.<span>Full Customized</span></li>
                                                <li>Responsive Design (Desktop / Laptop / Mobile Phones / Tablets)<span><i class="fas fa-check-circle"></i></span></li>
                                                <li><b>Products</b></li>
                                                <li>Brands<span>Unlimited</span></li>
                                                <li>Product Verticals<span>Unlimited</span></li>
                                                <li>Categories & Sub Categories<span>Unlimited</span></li>
                                                <li>Product Items<span>Unlimited</span></li>
                                                <li>Products Variants<span>Unlimited</span></li>
                                                <li><b>Store Backend Panel</b></li>
                                                <li>Customer Management<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Product Management<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Stock Management<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Order Management<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Manual Management<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Invoice Management<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Dispatch & Logistics Management<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Product Price Management<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Location Wise Pricing<span>Optional (for Multi Vendor)</span></li>
                                                <li>Offer Management (Offers / Discount coupons etc.,)<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Website Content Management<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Inventory Multi Locations<span>Optional (for Multi Vendor)</span></li>
                                                <li>SEO Management<span><i class="fas fa-check-circle"></i></span></li>
                                                <li><b>Reports</b></li>
                                                <li>Statistics Dashboard<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Sales Reports<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Product Reports<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Customer Reports<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Stock Reports<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Performance Summary<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Abondoned Cart Reports<span><i class="fas fa-check-circle"></i></span></li>
                                                <li><b>Security</b></li>
                                                <li>SSL Certificate<span>Included</span></li>
                                                <li>Data Encryption<span>Included</span></li>
                                                <li><b>Accounts</b></li>
                                                <li>Admin Accounts<span>1</span></li>
                                                <li>Staff User Accounts<span>Unlimited</span></li>
                                                <li>Staff Access Control<span><i class="fas fa-check-circle"></i></span></li>
                                                <li><b>Shipping & Logistics</b></li>
                                                <li>Integration with 3rd Party Logistic Companies<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Order Tracking for Customers<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Shipping Rates Customization<span><i class="fas fa-check-circle"></i></span></li>
                                                <li><b>Payment Gateway</b></li>
                                                <li>Payment Gateway Integration<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Payment Gateway Integration<span>PayU</span></li>
                                                <li>Debit & Credit Card<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Netbanking<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Payment Wallet<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Payment Transaction Fee<span>As Per Plan</span></li>
                                                <li><b>Support</b></li>
                                                <li>Email Support <span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Whatsapp Support<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Phone Support <span><i class="fas fa-check-circle"></i></span></li>
                                                <li><b>Bechofy Pricing</b></li>
                                                <li>Bechofy Transaction fees <span>0%</span></li>
                                                <li>One Time Setup Fees <span>Rs. 10000/-</span></li>
                                                <li>Plan Pricing <span>Rs. 25500/-</span></li>
                                                <li>Transactional SMS - 5000 <span>Rs. 2500/-</span></li>
                                                <li>Taxes (GST) <span>18%</span></li>
                                                <li><b>Terms & Conditions</b></li>
                                                <li>Payment Terms <span>100%</span></li>
                                                <li>Agreement Duration <span>3 Months</span></li>
                                                <li>One Time Data Migration / Seperation Fee (Optional) <span>Rs. 25000/-</span></li>

                                            </ul>
                                            <div class="text-center">
                                                <a href="#" class="btn outline-btn mb-3" target="_blank">Start your Store</a>
                                            </div>

                                        </div>
                                    </div>
                                </div> */}
                                <div class="col-lg-6 col-md-6 col-12">
                                    <div class="card popular-price  single-pricing-pack">
                                        <div class="price-name text-center">
                                            <h5 class="mb-0 py-4">Half Yearly Plan</h5>
                                        </div>
                                        <div class="card-header py-2 border-0 pricing-header">
                                            <div class="price text-center mb-0 monthly-price">Rs. 6500/- Per Month</div>

                                        </div>
                                        <div class="card-body">
                                            <ul class="list-unstyled mb-4 pricing-feature-list">
                                                <li><b>Domain & Hosting</b></li>
                                                <li>Custom Domain<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Secure Store Web Hosting<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Google Workspace Emails<span>Optional</span></li>
                                                <hr />   <li><b>Vendors</b></li>
                                                <li>Single Vendor<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Multi Vendor (Prices as per Customized Requirement)<span>Optional</span></li>
                                                <li><b>Store Front</b></li>
                                                <li>Online Store Includes ecommerce website and blog.<span>Full Customized</span></li>
                                                <li>Responsive Design (Desktop / Laptop / Mobile Phones / Tablets)<span><i class="fas fa-check-circle"></i></span></li>
                                                <hr />  <li><b>Products</b></li>
                                                <li>Brands<span>Unlimited</span></li>
                                                <li>Product Verticals<span>Unlimited</span></li>
                                                <li>Categories & Sub Categories<span>Unlimited</span></li>
                                                <li>Product Items<span>Unlimited</span></li>
                                                <li>Products Variants<span>Unlimited</span></li>
                                                <hr />
                                                <li><b>Store Backend Panel</b></li>
                                                <li>Customer Management<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Product Management<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Stock Management<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Order Management<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Manual Management<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Invoice Management<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Dispatch & Logistics Management<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Product Price Management<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Location Wise Pricing<span>Optional (for Multi Vendor)</span></li>
                                                <li>Offer Management (Offers / Discount coupons etc.,)<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Website Content Management<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Inventory Multi Locations<span>Optional (for Multi Vendor)</span></li>
                                                <li>SEO Management<span><i class="fas fa-check-circle"></i></span></li>
                                                <hr />
                                                <li><b>Reports</b></li>
                                                <li>Statistics Dashboard<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Sales Reports<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Product Reports<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Customer Reports<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Stock Reports<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Performance Summary<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Abondoned Cart Reports<span><i class="fas fa-check-circle"></i></span></li>
                                                <hr />
                                                <li><b>Security</b></li>
                                                <li>SSL Certificate<span>Included</span></li>
                                                <li>Data Encryption<span>Included</span></li>
                                                <hr />
                                                <li><b>Accounts</b></li>
                                                <li>Admin Accounts<span>1</span></li>
                                                <li>Staff User Accounts<span>Unlimited</span></li>
                                                <li>Staff Access Control<span><i class="fas fa-check-circle"></i></span></li>
                                                <hr />
                                                <li><b>Shipping & Logistics</b></li>
                                                <li>Integration with 3rd Party Logistic Companies<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Order Tracking for Customers<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Shipping Rates Customization<span><i class="fas fa-check-circle"></i></span></li>
                                                <hr />
                                                <li><b>Payment Gateway</b></li>
                                                <li>Payment Gateway Integration<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Payment Gateway Integration<span>PayU</span></li>
                                                <li>Debit & Credit Card<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Netbanking<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Payment Wallet<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Payment Transaction Fee<span>As Per Plan</span></li>
                                                <hr />
                                                <li><b>Support</b></li>
                                                <li>Email Support <span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Whatsapp Support<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Phone Support <span><i class="fas fa-check-circle"></i></span></li>
                                                <hr />
                                                <li><b>Bechofy Pricing</b></li>
                                                <li>Bechofy Transaction fees <span>0%</span></li>
                                                <li>Plan Pricing <span>Rs. 39,000/-</span></li>
                                                <li>One Time Setup Fees <span>Rs. 10,000/-</span></li>

                                                <li>Secure Hosting & Database <span>Rs. 12,500/-</span></li>
                                                <li>SSL Certificate <span>Rs. 6,000/- (Annual)</span></li>
                                                <li>Transactional SMS  <span>Rs. 5,000/-</span>
                                                    <br /><i>(1000 SMS + Sender ID + SMS Template Approval)</i></li>
                                                <li>Taxes (GST) <span>18%</span></li>
                                                <hr />
                                                <li><b>Terms & Conditions</b></li>
                                                <li>Payment Terms <span>100%</span></li>
                                                <li>Agreement Duration <span>6 Months</span></li>
                                                <li>10,000 SMS Topup (Optional) <span>Rs. 3,000/-</span></li>
                                                <li>One Time Data Migration / Seperation Fee (Optional) <span>Rs. 25,000/-</span></li>

                                            </ul>
                                            <div class="text-center">

                                                <a href="/store" class="btn primary-solid-btn mb-3" >Start your Store</a>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-12">
                                    <div class="card popular-price single-pricing-pack">

                                        <div class="price-name text-center">
                                            <h5 class="mb-0 py-4">Annual Plan</h5>
                                        </div>
                                        <div class="card-header py-2 border-0 pricing-header">
                                            <div class="price text-center mb-0 monthly-price">Rs. 5000/- Per Month</div>

                                        </div>
                                        <div class="card-body">
                                            <ul class="list-unstyled mb-4 pricing-feature-list">
                                                <li><b>Domain & Hosting</b></li>
                                                <li>Custom Domain<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Secure Store Web Hosting<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Google Workspace Emails<span>Optional</span></li>
                                                <hr />  <li><b>Vendors</b></li>
                                                <li>Single Vendor<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Multi Vendor (Prices as per Customized Requirement)<span>Optional</span></li>
                                                <li><b>Store Front</b></li>
                                                <li>Online Store Includes ecommerce website and blog.<span>Full Customized</span></li>
                                                <li>Responsive Design (Desktop / Laptop / Mobile Phones / Tablets)<span><i class="fas fa-check-circle"></i></span></li>
                                                <hr />  <li><b>Products</b></li>
                                                <li>Brands<span>Unlimited</span></li>
                                                <li>Product Verticals<span>Unlimited</span></li>
                                                <li>Categories & Sub Categories<span>Unlimited</span></li>
                                                <li>Product Items<span>Unlimited</span></li>
                                                <li>Products Variants<span>Unlimited</span></li>
                                                <hr /> <li><b>Store Backend Panel</b></li>
                                                <li>Customer Management<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Product Management<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Stock Management<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Order Management<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Manual Management<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Invoice Management<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Dispatch & Logistics Management<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Product Price Management<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Location Wise Pricing<span>Optional (for Multi Vendor)</span></li>
                                                <li>Offer Management (Offers / Discount coupons etc.,)<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Website Content Management<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Inventory Multi Locations<span>Optional (for Multi Vendor)</span></li>
                                                <li>SEO Management<span><i class="fas fa-check-circle"></i></span></li>
                                                <hr />  <li><b>Reports</b></li>
                                                <li>Statistics Dashboard<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Sales Reports<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Product Reports<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Customer Reports<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Stock Reports<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Performance Summary<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Abondoned Cart Reports<span><i class="fas fa-check-circle"></i></span></li>
                                                <hr /> <li><b>Security</b></li>
                                                <li>SSL Certificate<span>Included</span></li>
                                                <li>Data Encryption<span>Included</span></li>
                                                <hr /> <li><b>Accounts</b></li>
                                                <li>Admin Accounts<span>1</span></li>
                                                <li>Staff User Accounts<span>Unlimited</span></li>
                                                <li>Staff Access Control<span><i class="fas fa-check-circle"></i></span></li>
                                                <hr />  <li><b>Shipping & Logistics</b></li>
                                                <li>Integration with 3rd Party Logistic Companies<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Order Tracking for Customers<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Shipping Rates Customization<span><i class="fas fa-check-circle"></i></span></li>
                                                <hr /> <li><b>Payment Gateway</b></li>
                                                <li>Payment Gateway Integration<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Payment Gateway Integration<span>PayU</span></li>
                                                <li>Debit & Credit Card<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Netbanking<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Payment Wallet<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Payment Transaction Fee<span>As Per Plan</span></li>
                                                <hr /> <li><b>Support</b></li>
                                                <li>Email Support <span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Whatsapp Support<span><i class="fas fa-check-circle"></i></span></li>
                                                <li>Phone Support <span><i class="fas fa-check-circle"></i></span></li>
                                                <hr />
                                                <li><b>Bechofy Pricing</b></li>
                                                <li>Bechofy Transaction fees <span>0%</span></li>
                                                <li>Plan Pricing <span>Rs. 60,000/-</span></li>
                                                <li>One Time Setup Fees <span>Rs. 10,000/-</span></li>

                                                <li>Secure Hosting & Database <span>Rs. 25,000/-</span></li>
                                                <li>SSL Certificate <span>Rs. 6,000/- (Annual)</span></li>
                                                <li>Transactional SMS  <span>Rs. 5,000/-</span>
                                                    <br /><i>(1000 SMS + Sender ID + SMS Template Approval)</i></li>
                                                <li>Taxes (GST) <span>18%</span></li>
                                                <hr />
                                                <li><b>Terms & Conditions</b></li>
                                                <li>Payment Terms <span>100%</span></li>
                                                <li>Agreement Duration <span>12 Months</span></li>
                                                <li>10,000 SMS Topup (Optional) <span>Rs. 3,000/-</span></li>
                                                <li>One Time Data Migration / Seperation Fee (Optional) <span>Rs. 25,000/-</span></li>

                                            </ul>
                                            <div class="text-center">

                                                <a href="/store" class="btn primary-solid-btn mb-3" >Start your Store</a>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="support-cta text-center mt-5">
                                        <h5 class="mb-1"><span class="ti-headphone-alt color-primary mr-3"></span>We're Here to Help You
                                        </h5>
                                        <p>Have some questions? <a href="#">Chat with us now</a>, or <a href="#">send us an email</a> to
                                            get in touch.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>
                <Footer />

            </>
        )
    }
}
export default Pricing;