import React from 'react'

import Header from './Header'

import Footer from './Footer'
import Notiflix from "notiflix";

import PostApiCall from './Api'
import { Helmet } from "react-helmet";

var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
var SecurityCaptch = ''
for (var i = 0; i < 6; i++) {
    SecurityCaptch += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
}

class Service extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Name: '',
            Email: '',
            CompanyName: '',
            Mobile: '',
            Message: '',

            NumRegex: /^[0-9]*$/,
            EmailRegex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            Website: '',
            SecurityCode: ''

        };
    }


    sendMessage = () => {

        if (this.state.Name != '') {
            if (this.state.CompanyName != '') {
                if (this.state.Email != '') {
                    if (this.state.EmailRegex.test(this.state.Email)) {
                        if (this.state.Mobile != '') {
                            if (this.state.Mobile.length == 10) {
                                if (this.state.SecurityCode != '') {
                                    if (SecurityCaptch == this.state.SecurityCode) {
                                        this.onPostSave()
                                    }
                                    else {
                                        Notiflix.Notify.failure('security code is not valid');
                                    }

                                }
                                else {
                                    Notiflix.Notify.failure('Please enter security code');
                                }


                            }
                            else {
                                Notiflix.Notify.failure('Please enter valid mobile number');
                            }


                        }
                        else {
                            Notiflix.Notify.failure("Please enter your mobile number");
                        }
                    }
                    else {
                        Notiflix.Notify.failure("Please enter valid email");
                    }

                }
                else {
                    Notiflix.Notify.failure("Please select your email address");
                }

            }
            else {
                Notiflix.Notify.failure("Please enter your company name");
            }
        }
        else {
            Notiflix.Notify.failure("Please enter your name");
        }
    }


    onPostSave = () => {
        Notiflix.Loading.dots("Please wait...");

        PostApiCall.postRequest(
            {
                enquiryid: null,
                companyname: this.state.CompanyName,
                mobilenumber: this.state.Mobile,
                emailaddress: this.state.Email,
                country: null,
                state: null,
                city: null,
                websiteaddress: null,
                industry: null,
                customerid: null,
                agentname: null,
                enquirytype: 'From Service Page',
                updated_by: null,
                message: this.state.Message,
                name: this.state.Name,
                subject: null,
            },
            "AddEnquiry"
        ).then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    Notiflix.Loading.remove();
                    this.onPostMailer()
                }
            }
            ))
    }

    onPostMailer = () => {
        Notiflix.Loading.dots("Please wait...");
        PostApiCall.postRequest(
            {

                companyName: this.state.CompanyName,
                phone: this.state.Mobile,
                email: this.state.Email,
                name: this.state.Name,
                message: this.state.Message
            },
            "EnquiryMailer"
        ).then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    Notiflix.Loading.remove();

                    Notiflix.Notify.success("Your enquiry is successfully sent, our team will get back to you shortly. Thanks! ");

                }
                else {
                    Notiflix.Loading.remove();
                    Notiflix.Notify.failure(obj.data);

                }
            }
            ))

    }
    render() {

        return (
            <>
                <Helmet
                    title="How to start an e-commerce store with bechofy"
                    meta={[
                        {
                            property: "description",
                            content: "Bechofy's Consultation & implementation of the business store for e-commerce is an all-inclusive solution for getting your firm off the ground once you have an idea ready."
                        }
                    ]}
                />
                <Header />
                <div class="main">
                    <section class="ptb-25  gradient-bg ">
                        <div class="container">
                            <div class="row align-items-center">
                                <div class="col-md-12 col-lg-7">
                                    <div class="hero-slider-content hero-slider-content1 pt-5">
                                        <strong>Bechofy Services</strong>
                                        <h1 class="">Starting up an eCommerce Store?</h1>
                                        <p class="lead">Bechofy’s Customized Online Store is an one stop shop for all your Ecommerce needs.</p>

                                        <div class="action-btns mt-3">
                                            <a href="/store" class="btn secondary-solid-btn">Start your Store</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-lg-5">
                                    <div class="image-wrap pt-10">
                                        <img src="img/hero-img/Service-Ecommerce-store.png" class="img-fluid" alt="hero" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>





                    <section class="service-details-section ptb-100">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-md-10 col-lg-12">
                                    <div class="section-heading text-center">

                                        <h2>Setting up an Ecommerce store can be a tough task for Entrepreneurs & Existing Businesses</h2>
                                        <span class="animate-border mr-auto ml-auto mb-4"></span>
                                        <p class="lead">Bechofy is not just an eCommerce platform, it’s also a team of professionals who help you in setting up your ecommerce store. This involves consulting and implementing. </p>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <div class="col-lg-8 col-md-8">
                                    <div class="service-details-wrap">
                                        <img src="img/services/service-ecommerce-consulting.png" alt="services" class="img-fluid rounded shadow-sm" />
                                        <div class="services-detail-content mt-4">
                                            <p class="font-italic">Competition Research a Key</p>
                                            <h4>eCommerce Consulting</h4>
                                            <p class="text-justify">Every client who is looking to start an ecommerce business needs to understand a few things that's very important. Firstly they need to understand the competition of their business online. Bechofy works in understanding your business and then a team of business analysts work in doing a comprehensive analysis of competitors.</p>

                                            <p class="text-justify">
                                                This approach can be very useful so that you don't stand to lose money or business when you start up your online store.
                                            </p>

                                            <p class="text-justify">Bechofy gives you consultation on how to set up the online business, how marketing has to be done, what kind of budgets you need to have to spend on setting up your business and running it, help in providing packaging solutions and tie ups for Printing and Branding on print Media for Packaging.</p>
                                            <div class="cardes"><img src="img/praveen.png" class="img-fluid" alt=""/></div>
                                            <p class="font-italic">Chosing the right Plaftorm</p>

                                            <h4>eCommerce Implementation</h4>
                                            <p class="text-justify">Once you have the idea of your venture ready you need to have a proper implementation of the online business store.Bechofy’s platform for eCommerce is a full fledged solution to get your business started right away.</p>
                                            <p class="text-justify">Our team of designers work with you in building the fully customized storefront so that you have all that you need to showcase to your customers ready.</p>
                                            <p class="text-justify">We help you in having a payment gateway integrated with facilities for clients to pay for the products and services online using their credit card, debit card, net banking or payment wallets.</p>
                                            <p class="text-justify">Logistics is a key factor for any ecommerce business.At Bechofy we help you tie up with various logistics providers to ease your hunt for the right partner.Reliable providers are recommended to you and you can choose from them further to which we can have the logistics integrated with the Online Store.</p>
                                            <p class="text-justify">Our Products are all built using the latest technology and we strive to ensure that we update our technology with the latest in time.This helps you as clients to have the up to date platform at all given times.</p>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <div class="sidebar-right pl-4">
                                        <aside class="widget widget-categories">
                                            <h4>Ecommerce Consulting & Implementation</h4>
                                            <p class="text-justify font-italic">Selling your products and
                                                services online is a necessity
                                                today for most businesses.</p>
                                            <p class="text-justify">Creating a successful eCommerce business model is all about having the right approach and a robust backend support system to manage the business. This is the key to having your venture to work.</p>
                                        </aside>

                                        <aside class="widget widget-categories">
                                            <div class="contact-us-form gray-light-bg rounded">
                                                <h4>Are you ready to get started?</h4>
                                                <p>Write to us and our sales team will get back to you to understand your requirements and give you the suitable solution.</p>
                                                <div class="form-row">
                                                    <div class="col-12">
                                                        <div class="form-group">
                                                            <input type="text" class="form-control"
                                                                name="name" placeholder="Your name"
                                                                value={this.state.Name}
                                                                onChange={(name) => {
                                                                    this.setState({
                                                                        Name: name.target.value
                                                                    })

                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="form-group">
                                                            <input type="text" class="form-control"
                                                                name="name" placeholder="Company name"
                                                                value={this.state.CompanyName}
                                                                onChange={(text) => {
                                                                    this.setState({
                                                                        CompanyName: text.target.value
                                                                    })
                                                                }} />
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="form-group">
                                                            <input type="text" class="form-control" placeholder="Contact number"
                                                                value={this.state.Mobile}
                                                                onChange={(mobile) => {

                                                                    if ((this.state.NumRegex.test(mobile.target.value)) && (mobile.target.value.length <= 10)) {
                                                                        this.setState({

                                                                            Mobile: mobile.target.value
                                                                        })
                                                                    }

                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="form-group">
                                                            <input type="text" className="form-control" placeholder="Email Address"
                                                                value={this.state.Email}
                                                                onChange={(text) => {
                                                                    this.setState({
                                                                        Email: text.target.value
                                                                    })
                                                                }}

                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="form-group">
                                                            <textarea name="message" id="message" class="form-control" rows="5" cols="25" placeholder="Message"
                                                                value={this.state.Message}
                                                                onChange={(text) => {
                                                                    this.setState({
                                                                        Message: text.target.value
                                                                    })
                                                                }}

                                                            ></textarea>

                                                        </div>
                                                    </div>
                                                    <div class="col-5">
                                                        <div class="form-group">
                                                            <h4 className="captchaText">{SecurityCaptch}</h4>

                                                        </div>
                                                    </div>

                                                    <div class="col-7">
                                                        <div class="form-group">
                                                            <input type="text" className="form-control" placeholder="Enter Security Code"
                                                                value={this.state.SecurityCode}
                                                                onChange={(text) => {
                                                                    this.setState({
                                                                        SecurityCode: text.target.value
                                                                    })

                                                                }}

                                                            />
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-12 mt-3">
                                                        <button type="submit"
                                                            onClick={this.sendMessage}
                                                            class="btn primary-solid-btn" id="btnContactUs" style={{ pointerEvents: 'all', cursor: 'pointer' }}>
                                                            Send Message
                                                        </button>
                                                    </div>
                                                </div>

                                            </div>
                                        </aside>



                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer />
            </>
        )
    }
}

export default Service