import React from 'react';

import Header from './Header'

import Footer from './Footer'
import Notiflix from "notiflix";
import GetApiCall from './GETAPI';
import PostApiCall from './Api'

import { Helmet } from "react-helmet";


var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
var SecurityCaptch = ''
for (var i = 0; i < 6; i++) {
    SecurityCaptch += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
}


class ContactUs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Name: '',
            Email: '',
            CompanyName: '',
            Mobile: '',
            Location: '',
            Message: '',
            Industry: '',
            NumRegex: /^[0-9]*$/,
            EmailRegex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            Website: '',
            CityData: [],
            IndustryData: [],
            LocationName: '',
            IndustryName: '',
            SecurityCode: ''
        };
    }


    componentDidMount() {

        GetApiCall.getRequest("GetCityOnly").then((resultdes) =>
            resultdes.json().then((obj) => {
                console.log(obj.data)
                if (obj.data.length != 0) {
                    this.setState({
                        CityData: obj.data,
                        LocationName: obj.data[0].label,
                    });
                }

                Notiflix.Loading.remove();
            })
        );
        GetApiCall.getRequest("Get_Industry").then((resultdes) =>
            resultdes.json().then((obj) => {
                // console.log(obj.data)
                if (obj.data.length != 0) {
                    this.setState({
                        IndustryData: obj.data,
                        IndustryName: obj.data[0].label
                    });
                }

                Notiflix.Loading.remove();
            })
        );




    }


    sendMessage = () => {

        if (this.state.Name != '') {
            if (this.state.CompanyName != '') {
                if (this.state.Email != '') {
                    if (this.state.EmailRegex.test(this.state.Email)) {
                        if (this.state.Mobile != '') {
                            if (this.state.Mobile.length == 10) {
                                if (this.state.Location != '') {
                                    if (this.state.Industry != '') {
                                        if (this.state.SecurityCode != '') {
                                            if (SecurityCaptch == this.state.SecurityCode) {

                                                this.onPostSave()
                                            }
                                            else {
                                                Notiflix.Notify.failure('security code is not valid');
                                            }

                                        }
                                        else {
                                            Notiflix.Notify.failure('Please enter security code');
                                        }

                                    }
                                    else {
                                        Notiflix.Notify.failure('Please select your industry');
                                    }

                                }
                                else {
                                    Notiflix.Notify.failure('Please select your location');
                                }
                            }
                            else {
                                Notiflix.Notify.failure('Please enter valid mobile number');
                            }


                        }
                        else {
                            Notiflix.Notify.failure("Please enter your mobile number");
                        }
                    }
                    else {
                        Notiflix.Notify.failure("Please enter valid email");
                    }

                }
                else {
                    Notiflix.Notify.failure("Please select your email address");
                }

            }
            else {
                Notiflix.Notify.failure("Please enter your company name");
            }
        }
        else {
            Notiflix.Notify.failure("Please enter your name");
        }
    }

    onPostSave = () => {
        Notiflix.Loading.dots("Please wait...");

        PostApiCall.postRequest(
            {
                enquiryid: null,
                companyname: this.state.CompanyName,
                mobilenumber: this.state.Mobile,
                emailaddress: this.state.Email,
                country: null,
                state: null,
                city: this.state.Location,
                websiteaddress: this.state.Website,
                industry: this.state.Industry,
                customerid: null,
                agentname: null,
                enquirytype: 'From Create Store page',
                updated_by: null,
                message: this.state.Message,
                name: this.state.Name,
                subject: null,
            },
            "AddEnquiry"
        ).then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    Notiflix.Loading.remove();
                    this.onPostMailer()
                }
                else {
                    Notiflix.Loading.remove();
                    Notiflix.Notify.failure(obj.data);

                }
            }
            ))
    }

    onPostMailer = () => {
        Notiflix.Loading.dots("Please wait...");
        PostApiCall.postRequest(
            {

                companyName: this.state.CompanyName,
                phone: this.state.Mobile,
                email: this.state.Email,
                name: this.state.Name,
                message: this.state.Message,
                website: this.state.Website,

                location: this.state.LocationName,
                industry: this.state.IndustryName,

            },
            "StoreEnquiryMailer"
        ).then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    Notiflix.Loading.remove();

                    Notiflix.Notify.success("Your Store is Successfully created, our team will get back to you shortly. Thanks! ");

                }
                else {
                    Notiflix.Loading.remove();
                    Notiflix.Notify.failure(obj.data);

                }
            }
            ))

    }

    render() {
        return (
            <>

                <Helmet
                    title="Get your business online and start selling using our Ecommerce Solution"
                    meta={[
                        {
                            property: "description",
                            content: "Drive more clients to your business by taking your business online with our Comprehensive ECommerce Platform"
                        }
                    ]}
                />
                <Header />
                <div class="main">


                    <section class="ptb-25  gradient-bg ">
                        <div class="container">
                            <div class="row align-items-center">
                                <div class="col-md-12 col-lg-7">
                                    <div class="hero-slider-content hero-slider-content1  pt-5">
                                        <strong>Bechofy Store</strong>
                                        <h1 class="">Start Your Store </h1>
                                        <p class="lead">Get your business online and start selling using our Smart Ecommerce Solution</p>
                                    </div>
                                </div>
                                <div class="col-md-12 col-lg-5">
                                    <div class="image-wrap pt-10">
                                        <img src="img/hero-img/store.png" class="img-fluid" alt="hero" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>



                    <section class="contact-us-section ptb-100">
                        <div class="container contact">
                            <div class="row justify-content-center">
                                <div class="col-md-10 col-lg-12">
                                    <div class="section-heading text-center">

                                        <h2>Start Your Store</h2>
                                        <span class="animate-border mr-auto ml-auto mb-4"></span>
                                        <p class="lead">Drive more clients to your business by taking your business online with our Comprehensive ECommerce Platform.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 pb-3 message-box d-none ">
                                <div class="alert alert-danger"></div>
                            </div>
                            <div class="row justify-content-around mt-4">
                                <div class="col-md-7">
                                    <div class="contact-us-form  custom-shadow  rounded p-5">
                                        <h4>Do you have a fresh idea or an existing business that needs to go online to sell?</h4>
                                        <p>Bechofy - A gateway to Online Selling gives you all that you need to get your business online and start selling.</p>
                                        <div class="form-row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <input type="text" class="form-control"
                                                        name="name" placeholder="Your name *"
                                                        value={this.state.Name}
                                                        onChange={(name) => {
                                                            this.setState({
                                                                Name: name.target.value
                                                            })

                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <input type="text" class="form-control"
                                                        name="name" placeholder="Company name *"
                                                        value={this.state.CompanyName}
                                                        onChange={(text) => {
                                                            this.setState({
                                                                CompanyName: text.target.value
                                                            })
                                                        }} />
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <input type="text" class="form-control" placeholder="Contact number *"
                                                        value={this.state.Mobile}
                                                        onChange={(mobile) => {

                                                            if ((this.state.NumRegex.test(mobile.target.value)) && (mobile.target.value.length <= 10)) {
                                                                this.setState({

                                                                    Mobile: mobile.target.value
                                                                })
                                                            }

                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <input type="text" className="form-control" placeholder="Email Address *"
                                                        value={this.state.Email}
                                                        onChange={(text) => {
                                                            this.setState({
                                                                Email: text.target.value
                                                            })
                                                        }}

                                                    />
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <input type="text" className="form-control" placeholder="Website"
                                                        value={this.state.Website}
                                                        onChange={(text) => {
                                                            this.setState({
                                                                Website: text.target.value
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <select className="form-control"
                                                        value={this.state.Location}
                                                        onChange={(text) => {
                                                            this.setState({
                                                                Location: text.target.value
                                                            })
                                                            for (var i = 0; i < Object.keys(this.state.CityData).length; i++) {
                                                                if (this.state.CityData[i].value == text.target.value) {
                                                                    this.setState({
                                                                        LocationName: this.state.CityData[i].label,
                                                                    });
                                                                    // this.props.setcity(this.state.CityData[i].label);
                                                                }
                                                            }
                                                        }}

                                                    >
                                                        <option value=''>Select Your Location *</option>
                                                        {this.state.CityData.map((city) => (
                                                            <option
                                                                key={city.label}
                                                                value={city.value}
                                                            >
                                                                {city.label}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <select className="form-control"
                                                        value={this.state.Industry}
                                                        onChange={(text) => {
                                                            this.setState({
                                                                Industry: text.target.value
                                                            })
                                                            for (var i = 0; i < Object.keys(this.state.IndustryData).length; i++) {
                                                                if (this.state.IndustryData[i].value == text.target.value) {
                                                                    this.setState({
                                                                        IndustryName: this.state.IndustryData[i].label,
                                                                    });
                                                                    // this.props.setcity(this.state.CityData[i].label);
                                                                }
                                                            }
                                                        }}
                                                    >
                                                        <option value=''>Select Your Industry *</option>
                                                        {this.state.IndustryData.map((industry) => (
                                                            <option
                                                                key={industry.label}
                                                                value={industry.value}
                                                            >
                                                                {industry.label}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <textarea name="message" id="message" class="form-control" rows="5" cols="25" placeholder="Message"
                                                        value={this.state.Message}
                                                        onChange={(text) => {
                                                            this.setState({
                                                                Message: text.target.value
                                                            })
                                                        }}

                                                    ></textarea>
                                                </div>
                                            </div>
                                            <div class="col-5">
                                                <div class="form-group">
                                                    <h4 className="captchaText">{SecurityCaptch}</h4>

                                                </div>
                                            </div>
                                            <div class="col-7">
                                                <div class="form-group">
                                                    <input type="text" className="form-control" placeholder="Enter Security Code"
                                                        value={this.state.SecurityCode}
                                                        onChange={(text) => {
                                                            this.setState({
                                                                SecurityCode: text.target.value
                                                            })

                                                        }}

                                                    />
                                                </div>
                                            </div>

                                            <div class="col-sm-12 mt-3">
                                                <button class="btn primary-solid-btn"
                                                    onClick={this.sendMessage}>

                                                    Send Message
                                                </button>
                                            </div>
                                        </div>

                                        {/* <div class="promo-bottom-shape">
                                            <img src="img/promo-box-shape-bottom.png" alt="shape" />
                                        </div> */}
                                    </div>
                                </div>
                                <div class="col-md-5">
                                    <div class="contact-us-content">
                                        <h2>Selling your products Online made Easy!</h2>
                                        <p class="lead">A unified Ecommerce platform that quickly helps you to get your business started and helps you to sell online.</p>
                                        <img src="img/hero-img/store2.png" class="img-fluid" alt='' />




                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>



                </div>
                <Footer />

            </>
        )
    }
}
export default ContactUs;