import React from 'react';

import Header from './Header'

import Footer from './Footer'

import { Helmet } from "react-helmet";

class Marketing extends React.Component {
    render() {
        return (
            <>
                <Helmet
                    title="Marketing with bechofy on all digital platforms"
                    meta={[
                        {
                            property: "description",
                            content: "You can get engaging social media content & run online marketing campaigns on all digital marketing platforms such as Facebook & Google, by contacting Bechofy."
                        }
                    ]}
                />
                <Header />
                <div class="main">

                    <section class="ptb-25  gradient-bg ">
                        <div class="container">
                            <div class="row align-items-center">
                                <div class="col-md-12 col-lg-7">
                                    <div class="hero-slider-content hero-slider-content1  pt-5">
                                        <strong>Bechofy Marketing Solutions</strong>
                                        <h1 class="">Market your Online Store</h1>
                                        <p class="lead">Promoting your store to get more traffic and business is an ongoing process.</p>

                                        <div class="action-btns mt-3">
                                            <a href="/store" class="btn secondary-solid-btn">Start your Store</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-lg-5">
                                    <div class="image-wrap pt-10">
                                        <img src="img/hero-img/marketing.png" class="img-fluid" alt="hero" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section class="outstanding-section ptb-100">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-md-9 col-lg-10">
                                    <div class="section-heading text-center mb-1">

                                        <h2>Digital Marketing - A must for all online business ventures to get more customers</h2>
                                        <span class="animate-border mr-auto ml-auto mb-4"></span>
                                        <p class="lead">Setting up your online store is just the beginning. Once you have your store in place you need customers to find you. And in today’s competitive world you need to be very active in promotion to reach out to your customers and increase your sales. The fundamental platforms to promote your online store are Google & Social Media Platforms. Bechofy’s dedicated team of professionals assist you in delivering these services for your business.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 col-lg-12">
                                    <div class="feature-tabs-wrap">
                                        <ul class="nav nav-tabs border-bottom-0 feature-tabs feature-tabs-center d-flex justify-content-center" data-tabs="tabs">
                                            <li class="nav-item">
                                                <a class="nav-link text-center active" href="#feature-tab-1" data-toggle="tab">
                                                    <span class="ti-announcement icon-sm"></span>
                                                    <h6 class="mb-0">Search Engine Optimization (SEO)</h6>
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link text-center" href="#feature-tab-2" data-toggle="tab">
                                                    <span class="ti-light-bulb icon-sm"></span>
                                                    <h6 class="mb-0">Social Media Marketing</h6>
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link text-center " href="#feature-tab-3" data-toggle="tab">
                                                    <span class="ti-shield icon-sm"></span>
                                                    <h6 class="mb-0">Digital Advertising</h6>
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link text-center" href="#feature-tab-4" data-toggle="tab">

                                                    <span class="ti-email icon-sm"></span>
                                                    <h6 class="mb-0">Email Marketing</h6>
                                                </a>
                                            </li>

                                        </ul>
                                        <div class="tab-content  feature-tab-content">
                                            <div class="tab-pane active mt-5" id="feature-tab-1">
                                                <div class="row justify-content-around">
                                                    <div class="col-md-12 col-lg-7">
                                                        <div class="about-content-right  mb-md-4 mb-lg-0 my-md-3 my-lg-3 my-sm-0">
                                                            <h4>Get your Online Store Visible on Search Engines</h4>
                                                            <p class="text-justify">Getting your online store found on Search Engines is the key to getting more traffic. For doing so you need your online store to be SEO friendly and also have a regular ongoing process to get more traffic by means of On Page & Off Page SEO.</p>

                                                            <p class="text-justify">
                                                                Bechofy’s Team of SEO Specialists can work with you to promote your web store on Search Engines. The more traffic you get can result in growth of your business. Bechofy gives you the edge over competitors of your business by providing a holistic approach to managing the SEO of your Web Store.
                                                            </p>
                                                            <p class="text-justify">SEO involves both On Page and Off Page Activities. Although most of the On Page activities can be dynamically handled by the Backend Application of Bechofy, all Off Page activities are routine tasks that are handled by our team of professionals. </p>
                                                            <p class="text-justify">Reporting on a monthly basis and from time to time enables you to understand the growth your business is receiving in terms of awareness. Comprehensive reports are generated to understand the traffic and position the Search Optimization in a more effective way. </p>

                                                        </div>
                                                    </div>
                                                    <div class="col-md-12 col-lg-5">
                                                        <div class="img-wrap my-md-3 my-lg-3 my-sm-0">
                                                            <img src="img/t6-hero-img1.svg" alt="why choose us" class="img-fluid" />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane mt-5" id="feature-tab-2">
                                                <div class="row align-items-center justify-content-between">
                                                    <div class="col-md-6 col-lg-7">
                                                        <div class="about-content-right">
                                                            <h4>Engage with customers on social media platforms</h4>
                                                            <p class="text-justify">The power of social media today is very useful for your business. It helps you to reach out to targeted audiences in a very easy way. Having your business updates on social media will help you a lot. We provide 360 degree Social media marketing Solutions.</p>
                                                            <p class="text-justify">Every business today has the need to be on social media to get more awareness. Our Social Media Marketing team at Bechofy work closely with clients to understand their products and services and build marketing strategies and collateral to promote your business on Social media. </p>
                                                            <p class="text-justify">Regular social media campaigns help in having a strong name and brand recall for clients. Bechofy works with you in building brand awareness campaigns and how effectively they can be promoted. </p>
                                                            <p class="text-justify">Review of activities done along with supporting reports help you in understanding the growth as well as the awareness that has been created on social media. Most often we find that social media is always thought to be a mainstream promotion platform. Over the years these platforms have served to be a more economical way to promote your products and services.  </p>

                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 col-lg-5">
                                                        <div class="image-wrap">
                                                            <img src="img/marketing/Social-media.png" alt="Digital-Marketing" class="img-fluid " />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane  mt-5" id="feature-tab-3">
                                                <div class="row align-items-center">
                                                    <div class="col-lg-5 col-md-6">
                                                        <div class="image-wrap">
                                                            <img src="img/marketing/Digital-Advertising.png" alt="Digital-Marketing" class="img-fluid  shadow-sm" />
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-7 col-md-6">
                                                        <div class="about-content-right">
                                                            <h4>Advertise on Social Media & Search Platforms</h4>

                                                            <p class="text-justify">Advertising your business is the way forward to reach out to mass markets. Google Ads and Facebook / Instagram Advertising can help you reach out to customers which can be converted to sales. This is one easy way to get more visibility of your business. </p>
                                                            <p class="text-justify">Search ads are very much important today for your business. Most of the time people search for local businesses to buy products and being found at that search request is the key to gain growth for your business. Optimizing ads are very important along with the design and presentation of the ad campaign. </p>
                                                            <p class="text-justify">How much to spend and what to target is always a question most of the online store clients want to know. This can be very challenging as the figures are not something that can be determined initially. Our Ad Management team works with years of experience in analytics to identify the target audience and the target budget and give you concrete plans to build your marketing campaign.</p>

                                                            <p class="text-justify">Results from ads are the key to a successful campaign. Bechofy’s Team of Professional ad experts ensure that you get the right results from the campaigns that we design and publish.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane mt-5" id="feature-tab-4">
                                                <div class="row align-items-center">
                                                    <div class="col-lg-7 col-md-6">
                                                        <div class="about-content-right">
                                                            <h4>Send creative & Informative mailers to Customers</h4>
                                                            <p class="text-justify">Reaching out to your customers and other potential customers through email is a good way to get more traffic to your online store. This is an effective way to even remarket to customers who already know you.</p>
                                                            <p class="text-justify">Email marketing can be a very direct and personal way to communicate with your customers to promote your products and services. You can showcase new products to customers creating a buzz to get them to buy from you. </p>
                                                            <p class="text-justify">A classified and verified database of email addresses can be very useful to market your products and services. You can categorize customers based on their taste and likings to send them personalized emails. </p>
                                                            <p class="text-justify">Bechofy gives you solutions to cost effectively market your online store through email marketing. These solutions can help you to grow your business quickly.</p>
                                                            <p class="text-justify">Bechofy can also assist in creating beautiful customized HTML mailers to send to your customers. </p>

                                                        </div>
                                                    </div>
                                                    <div class="col-lg-5 col-md-6">
                                                        <div class="image-wrap">

                                                            <img src="img/marketing/email-marketing.png" alt="email-Marketing" class="img-fluid m-auto" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>
                <Footer />

            </>
        )
    }
}
export default Marketing;