import React from 'react'

import Header from './Header'

import Footer from './Footer'

class Terms extends React.Component {
    render() {
        return (
            <>
                <Header />
                <div class="main">

                    <section class="ptb-25  gradient-bg ">
                        <div class="container">
                            <div class="row align-items-center">
                                <div class="col-md-12 col-lg-7">
                                    <div class="hero-slider-content hero-slider-content1  pt-5">
                                        <strong>Bechofy Blank Page</strong>
                                        <h1 class="">Blank Page </h1>
                                        <p class="lead">Enthusiastically drive revolutionary opportunities before emerging leadership.</p>

                                        <div class="action-btns mt-3">
                                            <a href="/store" class="btn secondary-solid-btn" >Start your Store</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-lg-5">
                                    <div class="image-wrap pt-10">
                                        <img src="img/hero-img/terms-and-services.png" class="img-fluid" alt="hero" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer />
            </>
        )
    }
}

export default Terms