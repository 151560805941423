import React from 'react';



class Footer extends React.Component {
    render() {
        return (
            <>

                <div class="client-section ptb-100" style={{ background: 'url("../img/client-bg.jpg")no-repeat center center / cover' }}>
                    <div class="container">
                        <div class="row">
                            <div class="col-md-6 col-lg-6">
                                <div class="client-heading-wrap">
                                    <h3>Start Selling &amp; Get your Business Online</h3>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-6">
                                <div class="client-para">
                                    <p>We have got the right solution ready for you. Don't lose time searching for others when you can get started right away.</p>
                                    <h6><b>What are you waiting for? Get your online store ready now.</b></h6>
                                </div>
                            </div>
                        </div>
                        <div class="row align-items-center justify-content-center mb-4">
                            <div class="col-md-10 col-lg-10">
                                <div class="client-logo-wrap">
                                    <h2>Call <a href="tel:+917982828789" terget="_blank" style={{ color: 'black' }}>+91 79 8282 8789 </a> for more information</h2>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>




                <footer class="footer-section web">

                    <div class="footer-top bg-black">
                        <div class="container">
                            <div class="footeralign">
                                <ul class="footer-links">
                                    <li><b>QUICK LINKS </b></li>

                                    <li><a href="/overview">Bechofy Store</a></li>
                                    <li>
                                        <span>&#124;</span></li>
                                    <li><a href="/features">Features</a></li>
                                    <li>
                                        <span>&#124;</span></li>
                                    <li><a href="/services">Services</a></li>
                                    <li>
                                        <span>&#124;</span></li>
                                    <li><a href="/marketing">Marketing</a></li>
                                    <li>
                                        <span>&#124;</span></li>
                                    <li><a href="/pricing">Pricing</a></li>
                                    <li>
                                        <span>&#124;</span></li>
                                    <li><a href="/contactus">Contact Us</a></li>
                                    <li>
                                        <span>&#124;</span></li>
                                    <li><a href="/faq">FAQ</a></li>
                                </ul></div>


                            <div className="row">
                                <div className="col-md-3">
                                    <img src="img/logo-white.png" alt="logo" class="mt-4 img-fluid" />

                                </div>
                                <div class="col-md-3">
                                    <div class="footer-nav-wrap text-white">
                                        <div class="social-nav mt-4">
                                            <h4 class="text-white">FOLLOW US ON</h4>
                                            <ul class="list-unstyled social-list">
                                                <li class="list-inline-item tooltip-hover">
                                                    <a href="https://www.facebook.com/bechofy/" class="rounded"><span class="ti-facebook"></span></a>
                                                    <div class="tooltip-item">Facebook</div>
                                                </li>
                                                <li class="list-inline-item tooltip-hover"><a href="https://www.instagram.com/bechofy/" class="rounded"><span
                                                    class="ti-instagram"></span></a>
                                                    <div class="tooltip-item">Instagram</div>
                                                </li>
                                                <li class="list-inline-item tooltip-hover"><a href="https://www.twitter.com/bechofy_in/" class="rounded"><span
                                                    class="ti-twitter"></span></a>
                                                    <div class="tooltip-item">Twitter</div>
                                                </li>
                                                <li class="list-inline-item tooltip-hover"><a href="https://www.linkedin.com/in/bechofy-india-816aa7217" class="rounded">
                                                    <span
                                                        class="ti-linkedin"></span></a>
                                                    <div class="tooltip-item">Linkedin</div>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>


                                <div class="col-md-6 inweb">
                                    <div class="footer-nav-wrap text-white">
                                        <div class="social-nav mt-4">
                                            <h4 class="text-white">GET IN TOUCH</h4>
                                            <ul class="list-unstyled social-list" style={{ display: 'flex' }}>
                                                <a href="tel:+917982828789" target="_blank" style={{ color: '#fff' }}> <li class="list-inline-item tooltip-hover" style={{ display: 'flex' }}>
                                                    <a href="tel:+917982828789" class="rounded"><span class="ti-mobile"></span></a>
                                                    <span> &nbsp; +91 79 8282 8789 &nbsp;</span>
                                                    <div class="tooltip-item">Mobile</div>

                                                </li>
                                                </a>

                                                <a href="tel:+911147037722" target="_blank" style={{ color: '#fff' }}>
                                                    <li class="list-inline-item tooltip-hover" style={{ display: 'flex' }}>
                                                        <a href="tel:+911147037722" class="rounded"><span class="ti-headphone"></span></a>
                                                        <span> &nbsp; +91 11 4703 7722 &nbsp;</span>
                                                        <div class="tooltip-item">Phone</div>

                                                    </li>
                                                </a>

                                                <a href="mailto:wecare@bechofy.in" style={{ color: '#fff' }}>
                                                    <li class="list-inline-item tooltip-hover" style={{ display: 'flex' }}>
                                                        <a href="mailto:wecare@bechofy.in" class="rounded"><span class="ti-email"></span></a>
                                                        <span> &nbsp; wecare@bechofy.in</span>
                                                        <div class="tooltip-item">Email</div>

                                                    </li>

                                                </a>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 inTab">
                                    <div class="footer-nav-wrap text-white">
                                        <div class="social-nav mt-4">
                                            <h4 class="text-white">GET IN TOUCH</h4>
                                            <ul class="list-unstyled social-list" style={{ display: 'flex' }}>
                                                <a href="tel:+917982828789" target="_blank" style={{ color: '#fff' }}> <li class="list-inline-item tooltip-hover" style={{ display: 'flex' }}>
                                                    <a href="tel:+917982828789" class="rounded"><span class="ti-mobile"></span></a>
                                                    <span> &nbsp; +91 79 8282 8789 &nbsp;</span>
                                                    <div class="tooltip-item">Mobile</div>

                                                </li>
                                                </a>

                                                <a href="tel:+911147037722" target="_blank" style={{ color: '#fff' }}>
                                                    <li class="list-inline-item tooltip-hover" style={{ display: 'flex' }}>
                                                        <a href="tel:+911147037722" class="rounded"><span class="ti-headphone"></span></a>
                                                        <span> &nbsp; +91 11 4703 7722 &nbsp;</span>
                                                        <div class="tooltip-item">Phone</div>

                                                    </li>
                                                </a>

                                                <a href="mailto:wecare@bechofy.in" style={{ color: '#fff' }}>
                                                    <li class="list-inline-item tooltip-hover" style={{ display: 'flex' }}>
                                                        <a href="mailto:wecare@bechofy.in" class="rounded"><span class="ti-email"></span></a>
                                                        <span> &nbsp; wecare@bechofy.in</span>
                                                        <div class="tooltip-item">Email</div>

                                                    </li>

                                                </a>

                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="footer-bottom gradient-bg py-2">
                        <div class="container">
                            <div class="row align-items-center justify-content-between">
                                <div class="col-md-5 col-lg-5">
                                    <p class="copyright-text pb-0 mb-0">Copyrights © {new Date().getFullYear()}. Bechofy.in, All
                                        rights reserved.
                                    </p>
                                </div>
                                <div class="col-md-7 col-lg-6  d-md-block d-lg-block">
                                    <h3 class="text-right">
                                        <em>Bridging Technology & Business</em>
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>

                </footer>

                <footer class="footer-section mobile">

                    <div class="footer-top bg-black">
                        <div class="container">
                            <div className="row">
                                <div className="col-md-3">
                                    <img src="img/logo-white.png" alt="logo" class="img-fluid" />

                                </div>
                                <div class="col-md-3">
                                    <div class="footer-nav-wrap text-white">
                                        <div class="social-nav mt-2">
                                            <h4 class="text-white">FOLLOW US ON</h4>
                                            <ul class="list-unstyled social-list">
                                                <li class="list-inline-item tooltip-hover">
                                                    <a href="https://www.facebook.com/bechofy/" class="rounded"><span class="ti-facebook"></span></a>
                                                    <div class="tooltip-item">Facebook</div>
                                                </li>
                                                <li class="list-inline-item tooltip-hover"><a href="https://www.instagram.com/bechofy/" class="rounded"><span
                                                    class="ti-instagram"></span></a>
                                                    <div class="tooltip-item">Instagram</div>
                                                </li>
                                                <li class="list-inline-item tooltip-hover"><a href="https://www.twitter.com/bechofy_in/" class="rounded"><span
                                                    class="ti-twitter"></span></a>
                                                    <div class="tooltip-item">Twitter</div>
                                                </li>
                                                <li class="list-inline-item tooltip-hover"><a href="https://www.linkedin.com/in/bechofy-india-816aa7217" class="rounded">
                                                    <span
                                                        class="ti-linkedin"></span></a>
                                                    <div class="tooltip-item">Linkedin</div>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="footermobile mt-4">
                                <h5 style={{ color: 'white' }}><b>QUICK LINKS </b></h5>
                                <ul class="footer-links-mobile">
                                    <li><a href="/overview" style={{ color: '#fff' }}>Bechofy Store</a></li>

                                    <li><a href="/features" style={{ color: '#fff' }}>Features</a></li>

                                    <li><a href="/services" style={{ color: '#fff' }}>Services</a></li>

                                    <li><a href="/marketing" style={{ color: '#fff' }}>Marketing</a></li>

                                    <li><a href="/pricing" style={{ color: '#fff' }}>Pricing</a></li>

                                    <li><a href="/contactus" style={{ color: '#fff' }}>Contact Us</a></li>

                                    <li><a href="/faq" style={{ color: '#fff' }}>FAQ</a></li>
                                </ul>


                            </div>



                            <div className="row">



                                <div class="col-md-12">
                                    <div class="footer-nav-wrap text-white">
                                        <div class="social-nav mt-4">
                                            <h4 class="text-white">GET IN TOUCH</h4>
                                            <ul class="list-unstyled social-list">
                                                <a href="tel:+917982828789" target="_blank" style={{ color: '#fff' }}> <li class="list-inline-item tooltip-hover" style={{ display: 'flex' }}>
                                                    <a href="tel:+917982828789" class="rounded"><span class="ti-mobile"></span></a>
                                                    <span> &nbsp; +91 79 8282 8789 &nbsp;</span>
                                                    <div class="tooltip-item">Mobile</div>

                                                </li>
                                                </a>

                                                <a href="tel:+911147037722" target="_blank" style={{ color: '#fff' }} >
                                                    <li class="list-inline-item tooltip-hover" style={{ display: 'flex' }} className="mt-4">
                                                        <a href="tel:+911147037722" class="rounded"><span class="ti-headphone"></span></a>
                                                        <span> &nbsp; +91 11 4703 7722 &nbsp;</span>
                                                        <div class="tooltip-item">Phone</div>

                                                    </li>
                                                </a>

                                                <a href="mailto:wecare@bechofy.in" style={{ color: '#fff' }}>
                                                    <li class="list-inline-item tooltip-hover" style={{ display: 'flex' }} className="mt-4">
                                                        <a href="mailto:wecare@bechofy.in" class="rounded"><span class="ti-email"></span></a>
                                                        <span> &nbsp; wecare@bechofy.in</span>
                                                        <div class="tooltip-item">Email</div>

                                                    </li>

                                                </a>

                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="footer-bottom gradient-bg py-2">
                        <div class="container">
                            <div class="row align-items-center justify-content-between">
                                <div class="col-md-5 col-lg-5">
                                    <p class="copyright-text pb-0 mb-0">Copyrights © {new Date().getFullYear()}. Bechofy.in, All
                                        rights reserved.
                                    </p>
                                </div>
                                <div class="col-md-7 col-lg-6  d-md-block d-lg-block">
                                    <h3 class="text-right">
                                        <em>Bridging Technology & Business</em>
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>

                </footer>



                <div class="pulse1"></div>
                <div class="pulse2"></div>
                <div class="whatsapp-icon">
                    {/* <a
              href="https://wa.me/+917827795895"
              class="whatsapp_float"
              target="_blank"
              rel="noopener noreferrer"
            > */}
                    <a href="https://wa.me/+917982828789" target="_blank"> <img src="img/whatsapp.png" alt="whatsapp icon" />
                        <div class="pulse-bg p-3 position-absolute rounded-circle"></div>
                        <div class="pulse-bg-2 p-3 position-absolute rounded-circle"></div></a>

                </div>
                <div class="call-icon">
                    <a href="tel:+917982828789" target="_blank">
                        <img src="img/call.png" className="img-responsive" alt="call" />
                        <div class="pulse-bg-blue p-3 position-absolute rounded-circle"></div>
                        <div class="pulse-bg-2-blue p-3 position-absolute rounded-circle"></div>
                    </a>
                </div>
                <button class="scroll-top scroll-to-target" data-target="html">
                    <span class="ti-angle-up"></span>
                </button>
            </>
        )
    }
}
export default Footer;