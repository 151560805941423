import React from 'react';

import { Helmet } from "react-helmet";

class Home extends React.Component {
    render() {
        return (
            <>
                <Helmet
                    title="Best Ecommerce Platform in India | Custom Ecommerce Solutions | Bechofy"
                    meta={[
                        {
                            property: "description",
                            content: "Bechofy is a Simple & Robust Ecommerce platform with a fully customizable front end Ecommerce Website. You can start Selling your products & services online with ease using the Bechofy Platform."
                        },
                        {
                            property: "keyword",
                            content: "bechofy, bechofy store, Online selling, Ecommerce, Ecommerce store, online shop, ecommerce shop, ecommerce platform"
                        }
                    ]}
                />

                <div class="main">

                    <section class="hero-equal-height " style={{ background: 'url("img/t3-hero-shape.svg")no-repeat top center / cover' }}>
                        <div class="container">
                            <div class="row ">
                                <div class="col-md-12 col-lg-6">
                                    <div class="hero-slider-content pt-5 ">
                                        <span>Redefining Ecommerce Business</span>
                                        <h1 class="">Start your ONLINE STORE</h1>
                                        <p class="lead">Sell your Products & Services with Ease </p>

                                        <div class="action-btns mt-3">
                                            <a href="/store" class="btn secondary-solid-btn" style={{ color: 'black' }}>Start your Store</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-lg-6">
                                    <div class="image-wrap d-flex justify-content-end">
                                        <img src="img/hero.png" alt="hero" class="img-fluid custom-width" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* <!--hero section end-->

   


    <!--about section with promo start--> */}
                    <section class="about-with-promo ptb-100 gray-light-bg">
                        <div class="container">
                            <div class="row align-items-center">
                                <div class="col-lg-6">
                                    <div class="about-content-wrap">
                                        <strong class="color-secondary">Take your Business Online</strong>

                                        <h2>Selling online with Bechofy eCommerce enables you to get profitable results</h2>
                                        <span class="animate-border mb-4"></span>
                                        <p>eCommerce is the most effective way for customers to buy your products or services online. Every business today has to have an online presence.</p>
                                        <p>We at Bechofy make selling Simple. Our powerful robust eCommerce platform gives you a very easy way of managing your online store. We also give you a complete solution of running the store and also marketing your online business to reach more clients. You get a centralised panel to manage your store and customers. You can also manage payments and invoicing from the admin panel. </p>



                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="about-promo-wrap">
                                        <div class="row">
                                            <div class="col-md-6 col-sm-6 promo-col">
                                                <div class="promo-item">
                                                    <div class="promo-img">
                                                        <img src="img/b-icon/Cost-Effective.png" alt="services" width="60" />
                                                    </div>
                                                    <h5>Cost Effective</h5>
                                                    <p>Our pricing is affordable for all kinds of businesses</p>
                                                    <div class="promo-bottom-shape">
                                                        <img src="img/promo-box-shape-bottom.png" alt="shape" />
                                                    </div>
                                                </div>
                                                <div class="promo-item">
                                                    <div class="promo-img">
                                                        <img src="img/b-icon/Quick-setup.png" alt="services" width="60" />
                                                    </div>
                                                    <h5>Quick Setup</h5>
                                                    <p>The turnaround time taken to go live is very quick</p>
                                                    <div class="promo-bottom-shape">
                                                        <img src="img/promo-box-shape-bottom.png" alt="shape" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-sm-6 promo-col">
                                                <div class="promo-item">
                                                    <div class="promo-img">
                                                        <img src="img/b-icon/easy-to-use.png" alt="services" width="60" />
                                                    </div>
                                                    <h5>Easy to Use</h5>
                                                    <p>It’s simple and very easy to use for all businesses</p>
                                                    <div class="promo-bottom-shape">
                                                        <img src="img/promo-box-shape-bottom.png" alt="shape" />
                                                    </div>
                                                </div>
                                                <div class="promo-item">
                                                    <div class="promo-img">
                                                        <img src="img/b-icon/Business-Analytics.png" alt="services" width="60" />
                                                    </div>
                                                    <h5>Business Analytics</h5>
                                                    <p>Comprehensive analytics helps your business growth </p>
                                                    <div class="promo-bottom-shape">
                                                        <img src="img/promo-box-shape-bottom.png" alt="shape" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* <!--about section with promo end-->

    <!--call to action section start--> */}
                    <section class="call-to-action-video">
                        <div class="row m-0">
                            <div class="col-lg-6 col-md-12 p-0">
                                <div class="free-trial-image text-center">
                                    <img src="img/cta-2-girl-img.jpg" alt="image" class="img-fluid" />

                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12 p-0">
                                <div class="free-trial-content text-dark">
                                    <h2 class="text-dark">One Ecommerce Platform Just for you</h2>
                                    <p>You can set up the store and manage your entire online business right from one platform. </p>
                                    <h5 class="text-dark">Bechofy - a gateway for online selling</h5>
                                    <a href="#" class="btn secondary-solid-btn mt-3">Start your Store</a>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* <!--call to action section end-->

    <!--services section start--> */}
                    <section class="services-section ptb-100 gray-light-bg">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-md-12">
                                    <div class="section-heading text-center mb-5">
                                        <strong class="color-secondary">Who can use Bechofy</strong>
                                        <h2>Selling your products and services online is a necessity today for most businesses.</h2>
                                        <p class="lead">Bechofy is a product tailor made for any business that wants to sell online. Be it a small, Medium or Large business we have a solution for all. A one stop solution for all your online business needs. The various options that we have to start your online business make it affordable for all. Some of the businesses that can use Bechofy are listed below.</p>
                                        <span class="animate-border mr-auto ml-auto mb-4"></span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12 mb-3 d-flex justify-content-between">
                                    <div class="owl-carousel owl-theme dot-indicator icon-carousel">
                                        <div class="item">
                                            <div class="services-single text-center py-4  shadow-sm white-bg rounded">
                                                <img src="img/icon/Grocery.png" class="" alt="Grocery" />
                                            </div>
                                            <p class="text-center">Grocery</p>
                                            <div class="services-single text-center py-4  shadow-sm white-bg rounded">
                                                <img src="img/icon/Televisions.png" class="" alt="Televisions" />

                                            </div>
                                            <p class="text-center">Televisions</p>
                                        </div>
                                        <div class="item">
                                            <div class="services-single text-center py-4  shadow-sm white-bg rounded">
                                                <img src="img/icon/Food.png" class="" alt="Food" />

                                            </div>
                                            <p class="text-center">Food </p>
                                            <div class="services-single text-center py-4  shadow-sm white-bg rounded">
                                                <img src="img/icon/Home-Appliances.png" class="" alt="Home-Appliances" />

                                            </div>
                                            <p class="text-center">Home Appliances</p>
                                        </div>
                                        <div class="item">
                                            <div class="services-single text-center py-4  shadow-sm white-bg rounded">
                                                <img src="img/icon/Bakery.png" class="" alt="Bakery" />
                                            </div>
                                            <p class="text-center">Bakery</p>
                                            <div class="services-single text-center py-4  shadow-sm white-bg rounded">
                                                <img src="img/icon/Auto-Accessories.png" class="" alt="Auto-Accessories" />
                                            </div>
                                            <p class="text-center">Auto Accessories</p>
                                        </div>
                                        <div class="item">
                                            <div class="services-single text-center py-4  shadow-sm white-bg rounded">
                                                <img src="img/icon/Beverages.png" class="" alt="Beverages" />
                                            </div>
                                            <p class="text-center">Beverages</p>
                                            <div class="services-single text-center py-4  shadow-sm white-bg rounded">
                                                <img src="img/icon/Mobiles-&-Accessories.png" class="" alt="Mobiles-&-Accessories" />
                                            </div>
                                            <p class="text-center">Mobiles & Accessories</p>
                                        </div>
                                        <div class="item">
                                            <div class="services-single text-center py-4  shadow-sm white-bg rounded">
                                                <img src="img/icon/Cosmetics1.png" class="" alt="Cosmetics" />
                                            </div>
                                            <p class="text-center">Cosmetics</p>
                                            <div class="services-single text-center py-4  shadow-sm white-bg rounded">
                                                <img src="img/icon/Health.png" class="" alt="Health" />
                                            </div>
                                            <p class="text-center">Health</p>
                                        </div>
                                        <div class="item">
                                            <div class="services-single text-center py-4  shadow-sm white-bg rounded">
                                                <img src="img/icon/Gifts.png" class="" alt="Gifts" />
                                            </div>
                                            <p class="text-center">Gifts</p>
                                            <div class="services-single text-center py-4  shadow-sm white-bg rounded">
                                                <img src="img/icon/Beauty.png" class="" alt="Beauty" />
                                            </div>
                                            <p class="text-center">Beauty</p>
                                        </div>
                                        <div class="item">
                                            <div class="services-single text-center py-4  shadow-sm white-bg rounded">
                                                <img src="img/icon/Stationery.png" class="" alt="Stationery" />
                                            </div>
                                            <p class="text-center">Stationery</p>
                                            <div class="services-single text-center py-4  shadow-sm white-bg rounded">
                                                <img src="img/icon/Household.png" alt="Household" />
                                            </div>
                                            <p class="text-center">Household</p>
                                        </div>
                                        <div class="item">
                                            <div class="services-single text-center py-4  shadow-sm white-bg rounded">
                                                <img src="img/icon/Daily-Needs.png" alt="Daily-Needs" />
                                            </div>
                                            <p class="text-center">Daily Needs</p>
                                            <div class="services-single text-center py-4  shadow-sm white-bg rounded">
                                                <img src="img/icon/Kitchen.png" alt="icons" />
                                            </div>
                                            <p class="text-center">Kitchen </p>
                                        </div>
                                        <div class="item">
                                            <div class="services-single text-center py-4  shadow-sm white-bg rounded">
                                                <img src="img/icon/Pharmacy.png" alt="icons" />
                                            </div>
                                            <p class="text-center">Pharmacy</p>
                                            <div class="services-single text-center py-4  shadow-sm white-bg rounded">
                                                <img src="img/icon/Computers-&-Accessories.png" alt="icons" />
                                            </div>
                                            <p class="text-center">Computers</p>
                                        </div>
                                        <div class="item">
                                            <div class="services-single text-center py-4  shadow-sm white-bg rounded">
                                                <img src="img/icon/Home-Decor.png" alt="icons" />
                                            </div>
                                            <p class="text-center">Home Decor</p>
                                            <div class="services-single text-center py-4  shadow-sm white-bg rounded">
                                                <img src="img/icon/Travel-Accessories.png" alt="icons" />
                                            </div>
                                            <p class="text-center">Travel Accessories</p>
                                        </div>
                                        <div class="item">
                                            <div class="services-single text-center py-4  shadow-sm white-bg rounded">
                                                <img src="img/icon/Furniture-&-Furnishings.png" alt="icons" />
                                            </div>
                                            <p class="text-center">Furniture & Furnishings</p>
                                            <div class="services-single text-center py-4  shadow-sm white-bg rounded">
                                                <img src="img/icon/Baby-Products.png" alt="icons" />
                                            </div>
                                            <p class="text-center">Baby Products</p>
                                        </div>
                                        <div class="item">
                                            <div class="services-single text-center py-4  shadow-sm white-bg rounded">
                                                <img src="img/icon/Gadgets.png" alt="icons" />
                                            </div>
                                            <p class="text-center">Gadgets</p>
                                            <div class="services-single text-center py-4  shadow-sm white-bg rounded">
                                                <img src="img/icon/Pet-Supplies.png" alt="icons" />
                                            </div>
                                            <p class="text-center">Pet Supplies</p>
                                        </div>
                                        <div class="item">
                                            <div class="services-single text-center py-4  shadow-sm white-bg rounded">
                                                <img src="img/icon/Games-&-Toys.png" alt="icons" />
                                            </div>
                                            <p class="text-center">Games & Toys</p>
                                            <div class="services-single text-center py-4  shadow-sm white-bg rounded">
                                                <img src="img/icon/Industrial-Supplies.png" alt="icons" />
                                            </div>
                                            <p class="text-center">Industrial Supplies</p>
                                        </div>
                                        <div class="item">
                                            <div class="services-single text-center py-4  shadow-sm white-bg rounded">
                                                <img src="img/icon/Office-Supplies.png" alt="icons" />
                                            </div>
                                            <p class="text-center">Office Supplies</p>
                                            <div class="services-single text-center py-4  shadow-sm white-bg rounded">
                                                <img src="img/icon/Handicrafts.png" alt="icons" />
                                            </div>
                                            <p class="text-center">Handicrafts</p>
                                        </div>
                                        <div class="item">
                                            <div class="services-single text-center py-4  shadow-sm white-bg rounded">
                                                <img src="img/icon/Apparels.png" alt="icons" />
                                            </div>
                                            <p class="text-center">Apparels</p>
                                            <div class="services-single text-center py-4  shadow-sm white-bg rounded">
                                                <img src="img/icon/Sports.png" alt="icons" />
                                            </div>
                                            <p class="text-center">Sports</p>
                                        </div>
                                        <div class="item">
                                            <div class="services-single text-center py-4  shadow-sm white-bg rounded">
                                                <img src="img/icon/Jewellery.png" alt="icons" />
                                            </div>
                                            <p class="text-center">Jewellery</p>
                                            <div class="services-single text-center py-4  shadow-sm white-bg rounded">
                                                <img src="img/icon/Fitness.png" alt="icons" />
                                            </div>
                                            <p class="text-center">Fitness</p>
                                        </div>
                                        <div class="item">
                                            <div class="services-single text-center py-4  shadow-sm white-bg rounded">
                                                <img src="img/icon/Footwear.png" alt="icons" />
                                            </div>
                                            <p class="text-center">Footwear</p>
                                            <div class="services-single text-center py-4  shadow-sm white-bg rounded">
                                                <img src="img/icon/Music-Instruments.png" alt="icons" />
                                            </div>
                                            <p class="text-center">Music Instruments </p>
                                        </div>
                                        <div class="item">
                                            <div class="services-single text-center py-4  shadow-sm white-bg rounded">
                                                <img src="img/icon/Books.png" alt="icons" />
                                            </div>
                                            <p class="text-center">Books</p>
                                            <div class="services-single text-center py-4  shadow-sm white-bg rounded">
                                                <img src="img/icon/Home-Improvement.png" alt="icons" />
                                            </div>
                                            <p class="text-center">Home Improvement</p>
                                        </div>

                                    </div>
                                </div>



                            </div>
                        </div>
                    </section>
                    {/* <!--services section end-->

    <!--why choose us section start--> */}
                    <section class="why-choose-us ptb-100">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-md-10 col-lg-12">
                                    <div class="section-heading text-center">
                                        <strong class="color-secondary">Build your Business Online</strong>
                                        <h2>Bechofy’s powerful ecommerce platform makes it easy for businesses to go online</h2>
                                        <span class="animate-border mr-auto ml-auto mb-4"></span>
                                        <p class="lead">Creating a successful eCommerce business model is all about having the right approach and a robust backend support system to manage the business. This is the key to having your venture to work.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row align-items-center">

                                <div class="col-md-12 col-lg-12">
                                    <div class="why-choose-us-wrap">
                                        <div class="row">
                                            <div class="col-md-6 col-sm-6 promo-col">
                                                <div class="promo-item">
                                                    <div class="promo-img">
                                                        <img src="img/b-icon/New-Business.png" alt="services" width="60" />
                                                    </div>
                                                    <h5>Starting a New Business?</h5>
                                                    <p>A new venture in today's world is most effective when you also take it online from day one. If you have an idea or a concept of an online business model bring it to us and we can help you take it online and expand your markets. </p>
                                                    <div class="promo-bottom-shape">
                                                        <img src="img/promo-box-shape-bottom.png" alt="shape" />
                                                    </div>
                                                </div>
                                                <div class="promo-item">
                                                    <div class="promo-img">
                                                        <img src="img/b-icon/Expert-Help.png" alt="services" width="60" />
                                                    </div>
                                                    <h5>Let Bechofy’s Experts help you out</h5>
                                                    <p>Most businesses that are running using a traditional way of selling can now open their avenues by taking advantage of Bechofy’s simple process of getting their businesses online. This can enable you to get you more customers.</p>
                                                    <div class="promo-bottom-shape">
                                                        <img src="img/promo-box-shape-bottom.png" alt="shape" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-sm-6 promo-col">
                                                <div class="promo-item">
                                                    <div class="promo-img">
                                                        <img src="img/b-icon/Business-Online.png" alt="services" width="60" />
                                                    </div>
                                                    <h5>Take your Existing Business Online</h5>
                                                    <p>Streamlining your business using the latest technology is the need of the hour. We're dedicated to finding the best possible solution to every challenge, as well as a genuine passion to deliver with excellence.</p>
                                                    <div class="promo-bottom-shape">
                                                        <img src="img/promo-box-shape-bottom.png" alt="shape" />
                                                    </div>
                                                </div>
                                                <div class="promo-item">
                                                    <div class="promo-img">
                                                        <img src="img/b-icon/market-your-business.png" alt="services" width="60" />
                                                    </div>
                                                    <h5>Get Bechofy to Market your Business</h5>
                                                    <p>Every new or existing business venture needs to be effectively marketed to get more customers. Bechofy’s Team of expert digital marketing professionals can work with you to promote your business online using SEO & Social Media.</p>
                                                    <div class="promo-bottom-shape">
                                                        <img src="img/promo-box-shape-bottom.png" alt="shape" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                    {/* <!--why choose us section end-->

    <!--call to action section start--> */}
                    <section class="call-to-action ptb-25" style={{ background: 'url("img/cta-bg.jpg")no-repeat center center / cover' }}>
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-md-10 col-lg-9">
                                    <div class="section-heading text-center text-white ">
                                        <h2 class="text-white">Take your business to the next Level</h2>
                                        <p class="lead">Setup your Online Business using Bechofy</p>
                                        <a href="/store" class="btn primary-solid-btn ">Start your Store</a>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </section>
                    {/* <!--call to action section end-->
<!--Services section start--> */}
                    <section class="promo-section ptb-100">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-md-10 col-lg-12">
                                    <div class="section-heading text-center">
                                        <strong class="color-secondary">Services provided by Bechofy</strong>
                                        <h2>Launch your online store with Bechofy and get a complete Ecommerce Solution</h2>
                                        <span class="animate-border mr-auto ml-auto mb-4"></span>
                                        <p class="lead">We strive to ensure our clients get the necessary right solutions by having our strong qualified team understand & analyse your requirements before arriving at a suitable solution.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row justify-content-center">
                                <div class="col-md-6 col-lg-6">
                                    <div class="promo-single-wrap p-5  text-center custom-shadow rounded">
                                        <div class="promo-icon mb-4">
                                            <img src="img/b-icon/Ecommerce-Consulting.png" alt="services" width="120" />
                                            <span class="number-bg">01</span>
                                        </div>
                                        <div class="promo-info">
                                            <strong class="color-secondary">Ecommerce Consulting</strong>
                                            <h4>Competition Research is the Key</h4>
                                            <p>Before venturing into e-commerce you need to understand competition and the target that you need to focus on. Our business analyst team helps you in getting this sorted out for you and then work with you in building your online presence giving you the right solution both in technology and marketing.</p>
                                            <a href="services.html" class="btn primary-solid-btn mt-3">Read more</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6">
                                    <div class="promo-single-wrap p-5 text-center custom-shadow rounded">
                                        <div class="promo-icon mb-4">
                                            <img src="img/b-icon/Ecommerce-Implementation.png" alt="services" width="120" />
                                            <span class="number-bg">02</span>
                                        </div>
                                        <div class="promo-info">
                                            <strong class="color-secondary">Ecommerce Implementation</strong>
                                            <h4>Choosing the right Platform</h4>
                                            <p>Once we have the business model setup the next step is to identify which platform you need to use based on your infrastructure capabilities. We work with you in setting up your e-commerce store with the latest technology and the right marketing solution which can get you more Customers.</p>
                                            <a href="services.html" class="btn primary-solid-btn mt-3">Read more</a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                    {/* <!--Services section end--> */}
                    <section class="about-with-promo ptb-100 about-list">
                        <div class="container">
                            <div class="row align-items-center">
                                <div class="col-lg-8">
                                    <div class="about-content-wrap">
                                        <strong class="color-secondary">Market your Online Store</strong>
                                        <h2>Promoting your store to get more traffic and business is an ongoing process.</h2>
                                        <span class="animate-border mb-4"></span>
                                        <p>Getting more customers means you need to have a good awareness of your online store. Digital marketing is the key to getting your online presence known.</p>



                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="about-us-img">
                                        <img src="img/seo.png" alt="about us" class="img-fluid about-single-img" />
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <ul class="check-list-info">
                                        <li><strong>SEO - Search Engine Optimization</strong><br />
                                            Getting your online store found on Search Engines is the key to getting more traffic. For doing so you need your online store to be SEO friendly and also have a regular ongoing process to get more traffic by means of On Page & Off Page SEO.<br />

                                        </li>
                                        <li><strong>Social Media Marketing</strong><br />
                                            The power of social media today is very useful for your business. It helps you to reach out to targeted audiences in a very easy way. Having your business updates on social media will help you a lot. We provide 360 degree Social media marketing Solutions.<br />

                                        </li>
                                        <li><strong>Digital Advertising</strong><br /> Advertising your business is the way forward to reach out to mass markets. Google Ads and Facebook / Instagram Advertising can help you reach out to customers which can be converted to sales. This is one easy way to get more visibility of your business.<br />

                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section class="why-choose-us ptb-100">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-md-10 col-lg-12">
                                    <div class="section-heading text-center">
                                        <strong class="color-secondary">How can Bechofy Experts help you?</strong>
                                        <h2>You need a team of professionals to help you in setting up your Online Store</h2>
                                        <span class="animate-border mr-auto ml-auto mb-4"></span>
                                        <p class="lead">Getting a team to work with you in building your online business is a very good thing. A trusted and dedicated team of professionals at Bechofy not only help you in building the store but also in promoting your business on a day to day basis.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row align-items-center">

                                <div class="col-md-12 col-lg-7">
                                    <div class="why-choose-us-wrap">
                                        <div class="row">
                                            <div class="col-md-6 col-sm-6 promo-col">
                                                <div class="promo-item">
                                                    <div class="promo-img">
                                                        <img src="img/b-icon/consulting-team.png" alt="services" width="60" />
                                                    </div>
                                                    <h5>Consulting Team</h5>
                                                    <p>Our team of Ecommerce Business Analysts & Consultants help you in setting the right path to creating your idea into a successful online business.</p>
                                                    <div class="promo-bottom-shape">
                                                        <img src="img/promo-box-shape-bottom.png" alt="shape" />
                                                    </div>
                                                </div>
                                                <div class="promo-item">
                                                    <div class="promo-img">
                                                        <img src="img/b-icon/development.png" alt="services" width="60" />
                                                    </div>
                                                    <h5>Development & Production Team</h5>
                                                    <p>Our Strong Tech Team helps you in building a customized online store for your business and integrate with the powerful Bechofy Backend Tool.</p>
                                                    <div class="promo-bottom-shape">
                                                        <img src="img/promo-box-shape-bottom.png" alt="shape" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-sm-6 promo-col">
                                                <div class="promo-item">
                                                    <div class="promo-img">
                                                        <img src="img/b-icon/marketing-team.png" alt="services" width="60" />
                                                    </div>
                                                    <h5>Marketing Team</h5>
                                                    <p>In getting your business a kickstart our marketing team works with you on a regular basis to generate more sales and traffic for your Online Store.</p>
                                                    <div class="promo-bottom-shape">
                                                        <img src="img/promo-box-shape-bottom.png" alt="shape" />
                                                    </div>
                                                </div>
                                                <div class="promo-item">
                                                    <div class="promo-img">
                                                        <img src="img/b-icon/support-icon.png" alt="services" width="60" />
                                                    </div>
                                                    <h5>Support Team</h5>
                                                    <p>Every client would need one on one support to manage your online store and our team of Support Specialists help you with any requirements you have. </p>
                                                    <div class="promo-bottom-shape">
                                                        <img src="img/promo-box-shape-bottom.png" alt="shape" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-lg-5">
                                    <div class="image-wrap">
                                        <img src="img/side.png" alt="why choose us" class="img-fluid m-auto " />
                                        <p></p>
                                        <h3>Reach out to us!</h3>
                                        <p class="text-justify">You need patience to let your product grow. Getting an idea to succeed involves having a strong team backing it up. Bechofy ensures that all of that is taken care of using technology to power your online business. The combination of Consulting, Development, Marketing and a proper support system makes us your most reliable ecommerce solution provider.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>






                    {/* <!--testimonial section start--> */}
                    {/* <section class="testimonial-section ptb-100" style={{ background: '#000' }}>
                        <div class="container">
                            <div class="row align-items-center">
                                <div class="col-md-6 col-lg-5">
                                    <div class="testimonial-heading text-white">
                                        <h2 class="text-white">What Our Clients Say About Us</h2>
                                        <span class="animate-border mb-4"></span>
                                        <p>Our happy clients give you more reason for you to be part of the Bechofy Community of Online Store Owners.</p>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6">
                                    <div class="testimonial-content-wrap">

                                        <div class="owl-carousel owl-theme client-testimonial-1 custom-dot testimonial-shape">
                                            <div class="item">
                                                <div class="testimonial-quote-wrap">
                                                    <div class="media author-info mb-3">
                                                        <div class="author-img mr-3">
                                                            <img src="img/client-1.jpg" alt="client" class="img-fluid" />
                                                        </div>
                                                        <div class="media-body text-dark">
                                                            <h5 class="mb-0 text-dark">John Charles</h5>
                                                            <span>BizBite</span>
                                                        </div>
                                                        <span class="fas fa-quote-right icon-md text-dark"></span>
                                                    </div>
                                                    <div class="client-say text-dark">
                                                        <p>Interactively optimize
                                                            fully researched expertise vis-a-vis plug-and-play relationships. Intrinsicly
                                                            develop viral core competencies for fully tested customer service.
                                                            Enthusiastically create next-generation growth strategies and.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="item">
                                                <div class="testimonial-quote-wrap">
                                                    <div class="media author-info mb-3">
                                                        <div class="author-img mr-3">
                                                            <img src="img/client-2.jpg" alt="client" class="img-fluid" />
                                                        </div>
                                                        <div class="media-body text-dark">
                                                            <h5 class="mb-0 text-dark">Arabella Ora</h5>
                                                            <span>BizBite</span>
                                                        </div>
                                                        <span class="fas fa-quote-right icon-md text-dark"></span>
                                                    </div>
                                                    <div class="client-say text-dark">
                                                        <p>Rapidiously develop user
                                                            friendly growth strategies after extensive initiatives. Conveniently grow
                                                            innovative benefits through fully tested deliverables. Rapidiously utilize
                                                            focused platforms through end-to-end schemas.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="item">
                                                <div class="testimonial-quote-wrap">
                                                    <div class="media author-info mb-3">
                                                        <div class="author-img mr-3">
                                                            <img src="img/client-3.jpg" alt="client" class="img-fluid" />
                                                        </div>
                                                        <div class="media-body text-dark">
                                                            <h5 class="mb-0 text-dark">Jeremy Jere</h5>
                                                            <span>BizBite</span>
                                                        </div>
                                                        <span class="fas fa-quote-right icon-md text-dark"></span>
                                                    </div>
                                                    <div class="client-say text-dark">
                                                        <p>Objectively synthesize
                                                            client-centered e-tailers for maintainable channels. Holisticly administrate
                                                            customer directed vortals whereas tactical functionalities. Energistically
                                                            monetize reliable imperatives through client-centric best practices.
                                                            Collaboratively.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="item">
                                                <div class="testimonial-quote-wrap">
                                                    <div class="media author-info mb-3">
                                                        <div class="author-img mr-3">
                                                            <img src="img/client-4.jpg" alt="client" class="img-fluid" />
                                                        </div>
                                                        <div class="media-body text-dark">
                                                            <h5 class="mb-0 text-dark">John Charles</h5>
                                                            <span>BizBite</span>
                                                        </div>
                                                        <span class="fas fa-quote-right icon-md text-dark"></span>
                                                    </div>
                                                    <div class="client-say text-dark">
                                                        <p>Enthusiastically innovate
                                                            B2C data without clicks-and-mortar convergence. Monotonectally deliver
                                                            compelling testing procedures vis-a-vis B2B testing procedures. Competently
                                                            evisculate integrated resources whereas global processes. Enthusiastically.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
    
                    <section class="our-blog-section ptb-100">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-md-10 col-lg-12">
                                    <div class="section-heading text-center mb-5">
                                        <strong class="color-secondary">News and Blog</strong>
                                        <h2>We get you interesting things to read about</h2>
                                        <span class="animate-border mr-auto ml-auto mb-4"></span>
                                        <p class="lead">
                                            Ecommerce News, Information, Market Research, Case Studies, Etc.,
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="single-blog-card card border-0 shadow-sm">
                                        <div class="blog-img position-relative">
                                            <img src="img/blog/1.jpg" class="card-img-top" alt="blog" />
                                            <div class="meta-date">
                                                <strong>24</strong>
                                                <small>Apr</small>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="post-meta mb-2">
                                                <ul class="list-inline meta-list">
                                                    <li class="list-inline-item"><i class="fas fa-heart mr-2"></i><span>45</span> Comments</li>
                                                    <li class="list-inline-item"><i class="fas fa-share-alt mr-2"></i><span>10</span> Share</li>
                                                </ul>
                                            </div>
                                            <h3 class="h5 mb-2 card-title"><a href="#">Appropriately productize fully</a></h3>
                                            <p class="card-text">Some quick example text to build on the card title and make up the bulk.</p>
                                            <a href="#" class="detail-link">Read more <span class="ti-arrow-right"></span></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="single-blog-card card border-0 shadow-sm">
                                        <div class="blog-img position-relative">
                                            <img src="img/blog/2.jpg" class="card-img-top" alt="blog" />
                                            <div class="meta-date">
                                                <strong>24</strong>
                                                <small>Apr</small>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="post-meta mb-2">
                                                <ul class="list-inline meta-list">
                                                    <li class="list-inline-item"><i class="fas fa-heart mr-2"></i><span>45</span> Comments</li>
                                                    <li class="list-inline-item"><i class="fas fa-share-alt mr-2"></i><span>10</span> Share</li>
                                                </ul>
                                            </div>
                                            <h3 class="h5 mb-2 card-title"><a href="#">Quickly formulate backend</a></h3>
                                            <p class="card-text">Synergistically engage effective ROI after customer directed partnerships.</p>
                                            <a href="#" class="detail-link">Read more <span class="ti-arrow-right"></span></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="single-blog-card card border-0 shadow-sm">
                                        <div class="blog-img position-relative">
                                            <img src="img/blog/3.jpg" class="card-img-top" alt="blog" />
                                            <div class="meta-date">
                                                <strong>24</strong>
                                                <small>Apr</small>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="post-meta mb-2">
                                                <ul class="list-inline meta-list">
                                                    <li class="list-inline-item"><i class="fas fa-heart mr-2"></i><span>45</span> Comments</li>
                                                    <li class="list-inline-item"><i class="fas fa-share-alt mr-2"></i><span>10</span> Share</li>
                                                </ul>
                                            </div>
                                            <h3 class="h5 mb-2 card-title"><a href="#">Objectively extend extensive</a></h3>
                                            <p class="card-text">Holisticly mesh open-source leadership rather than proactive users. </p>
                                            <a href="#" class="detail-link">Read more <span class="ti-arrow-right"></span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> */}



                </div>

            </>
        )
    }
}
export default Home;