import React from 'react';

class Header extends React.Component {



    render() {
        return (
            <>
                <div id="preloader">
                    <div className="loader1">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>


                <header className="header">
                    <nav className="navbar navbar-expand-lg fixed-top bg-transparent">
                        <div className="container">
                            <a className="navbar-brand" href="/">
                                <img src="img/bechofy.png" alt="logo" className="img-fluid" />
                            </a>
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="ti-menu"></span>
                            </button>
                            <div className="collapse navbar-collapse h-auto" id="navbarSupportedContent">
                                <ul className="navbar-nav ml-auto menu">
                                    <li className="d-lg-none ">
                                        <button data-toggle="collapse" data-target="#navbarSupportedContent" className="close text-left">&times;</button>
                                    </li>
                                    <li><a href="/overview">Bechofy Store</a></li>
                                    <li><a href="/features">Features</a></li>
                                    <li><a href="/services">Services</a></li>
                                    <li><a href="/marketing">Marketing</a></li>
                                    <li><a href="/pricing">Pricing</a></li>
                                    <li><a href="/contactus">Contact Us</a></li>
                                    <li><a href="/store" className="btn primary-solid-btn check-btn">Start your Store</a></li>
                                    <li className="d-lg-none social text-center">
                                        <p>Email<br /><a href="mailto:info@bechofy.in">info@bechofy.in</a><br />Phone Number<br /><a href="tel:+91 79 8282 8789">+91 79 8282 8789</a></p>
                                        <h6 className="text-dark text-center">FOLLOW US ON</h6>
                                        <a href="https://www.facebook.com/bechofy/" className="rounded"><span className="ti-facebook"></span></a>
                                        <a href="https://www.instagram.com/bechofy/" className="rounded"><span className="ti-instagram"></span></a>
                                        <a href="https://www.twitter.com/bechofy_in/" class="rounded"><span
                                            class="ti-twitter"></span></a>
                                        <a href="https://www.linkedin.com/in/bechofy-india-816aa7217" class="rounded">
                                            <span
                                                class="ti-linkedin"></span></a>
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </nav>
                </header>
            </>
        )
    }




}

export default Header;