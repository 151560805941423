import React from 'react';

import Header from './Header'

import Footer from './Footer'

import { Helmet } from "react-helmet";

class FAQ extends React.Component {
    render() {
        return (
            <>
                <Helmet
                    title="Bechofy FAQ"
                    meta={[
                        {
                            property: "description",
                            content: "To make a trustworthy relationship with our clients, we ensure that all your questions are answered."
                        }
                    ]}
                />
                <Header />
                <div class="main">


                    <section class="ptb-25  gradient-bg ">
                        <div class="container">
                            <div class="row align-items-center">
                                <div class="col-md-12 col-lg-7">
                                    <div class="hero-slider-content hero-slider-content1  pt-5">
                                        <strong>Bechofy FAQ</strong>
                                        <h1 class="">You might have some questions.</h1>
                                        <p class="lead">We have identified a few questions that you would need answers to.</p>

                                        <div class="action-btns mt-3">
                                            <a href="/store" class="btn secondary-solid-btn">Start your Store</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-lg-5">
                                    <div class="image-wrap pt-10">
                                        <img src="img/hero-img/faq.png" class="img-fluid" alt="hero" />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section class="promo-section ptb-100">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-lg-10 col-md-9">
                                    <div class="section-heading mb-5 text-center">
                                        <h2>Frequently Asked Questions</h2>
                                        <span class="animate-border mr-auto ml-auto mb-4"></span>
                                        <p class="lead">
                                            We constantly work on building a close relationship with our clients. And in this endeavor we ensure that we answer all your questions for you to build a trustworthy relationship in using our services.

                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12">
                                    <div id="accordion-1" class="accordion accordion-faq">

                                        <div class="card">
                                            <div class="card-header py-4" id="heading-1-1" data-toggle="collapse" role="button"
                                                data-target="#collapse-1-1" aria-expanded="false" aria-controls="collapse-1-1">
                                                <h6 class="mb-0"><span class="ti-receipt mr-3"></span> Does Bechofy charge a setup fee?</h6>
                                            </div>
                                            <div id="collapse-1-1" class="collapse" aria-labelledby="heading-1-1"
                                                data-parent="#accordion-1">
                                                <div class="card-body">
                                                    <p>Yes we have a one time setup fee. This fee is used to ensure that your store is set up and all integrations with your website which is customized is taken care of.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card">
                                            <div class="card-header py-4" id="heading-1-2" data-toggle="collapse" role="button"
                                                data-target="#collapse-1-2" aria-expanded="false" aria-controls="collapse-1-2">
                                                <h6 class="mb-0"><span class="ti-gallery mr-3"></span> Do I need to pay for using the Bechofy Services?</h6>
                                            </div>
                                            <div id="collapse-1-2" class="collapse" aria-labelledby="heading-1-2"
                                                data-parent="#accordion-1">
                                                <div class="card-body">
                                                    <p>You have 2 options (Half Yearly Plan & Annual Plan). This fee is for using our platform. Bechofy Does NOT charge any Order transaction charges. This means you don't have to pay for any orders that come on your site.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card">
                                            <div class="card-header py-4" id="heading-1-3" data-toggle="collapse" role="button"
                                                data-target="#collapse-1-3" aria-expanded="false" aria-controls="collapse-1-3">
                                                <h6 class="mb-0"><span class="ti-wallet mr-3"></span> Can I cancel my account at any time?Can I cancel my account at any time?
                                                </h6>
                                            </div>
                                            <div id="collapse-1-3" class="collapse" aria-labelledby="heading-1-3"
                                                data-parent="#accordion-1">
                                                <div class="card-body">
                                                    <p>You can cancel your services anytime but then your payment which has been made for the services will have to be forfeited.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card">
                                            <div class="card-header py-4" id="heading-1-4" data-toggle="collapse" role="button"
                                                data-target="#collapse-3-7" aria-expanded="false" aria-controls="collapse-1-3">
                                                <h6 class="mb-0"><span class="ti-lock mr-3"></span> Is my Data Secure?
                                                </h6>
                                            </div>
                                            <div id="collapse-3-7" class="collapse" aria-labelledby="heading-1-3"
                                                data-parent="#accordion-1">
                                                <div class="card-body">
                                                    <p>All crucial information is encrypted and your website and backend panel is SSL Enabled. All data is stored in Secure Web Servers and Databases.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card">
                                            <div class="card-header py-4" id="heading-1-5" data-toggle="collapse" role="button"
                                                data-target="#collapse-3-8" aria-expanded="false" aria-controls="collapse-1-3">
                                                <h6 class="mb-0"><span class="ti-wallet mr-3"></span> How long are your contracts?

                                                </h6>
                                            </div>
                                            <div id="collapse-3-8" class="collapse" aria-labelledby="heading-1-3"
                                                data-parent="#accordion-1">
                                                <div class="card-body">
                                                    <p>We have 2 plans: Half Yearly & Annual Plans. The Contracts start from the day we have the setup of your panel made. </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div id="accordion-2" class="accordion accordion-faq">

                                        <div class="card">
                                            <div class="card-header py-4" id="heading-2-1" data-toggle="collapse" role="button"
                                                data-target="#collapse-2-1" aria-expanded="false" aria-controls="collapse-2-1">
                                                <h6 class="mb-0"><span class="ti-receipt mr-3"></span> Can I change my plan?
                                                </h6>
                                            </div>
                                            <div id="collapse-2-1" class="collapse" aria-labelledby="heading-2-1"
                                                data-parent="#accordion-2">
                                                <div class="card-body">
                                                    <p>Yes you can change your plan from Half Yearly to Annual but you cant Change from an Yearly Plan to Half Yearly plan.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card">
                                            <div class="card-header py-4" id="heading-2-2" data-toggle="collapse" role="button"
                                                data-target="#collapse-2-2" aria-expanded="false" aria-controls="collapse-2-2">
                                                <h6 class="mb-0"><span class="ti-shopping-cart mr-3"></span> Can I sell any products on the Bechofy Platform?
                                                </h6>
                                            </div>
                                            <div id="collapse-2-2" class="collapse" aria-labelledby="heading-2-2"
                                                data-parent="#accordion-2">
                                                <div class="card-body">
                                                    <p>All products that are legal and legit can be sold on our platform. Bechofy will not be responsible in any way for the products that you sell. Bechofy reserves the right to allow and disallow any products that you wish to sell on our platform.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card">
                                            <div class="card-header py-4" id="heading-2-3" data-toggle="collapse" role="button"
                                                data-target="#collapse-2-3" aria-expanded="false" aria-controls="collapse-2-3">
                                                <h6 class="mb-0"><span class="ti-widget mr-3"></span> Is my website going to be Customized?
                                                </h6>
                                            </div>
                                            <div id="collapse-2-3" class="collapse" aria-labelledby="heading-2-3"
                                                data-parent="#accordion-2">
                                                <div class="card-body">
                                                    <p>Bechofy’s Selling point is that all websites made for clients are going to be Customized. Only the Backend Panel is going to be a fixed Bechofy Platform.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card">
                                            <div class="card-header py-4" id="heading-2-4" data-toggle="collapse" role="button"
                                                data-target="#collapse-2-4" aria-expanded="false" aria-controls="collapse-2-3">
                                                <h6 class="mb-0"><span class="ti-widget mr-3"></span>Are there any additional costs that will get added?

                                                </h6>
                                            </div>
                                            <div id="collapse-2-4" class="collapse" aria-labelledby="heading-2-3"
                                                data-parent="#accordion-2">
                                                <div class="card-body">
                                                    <p>Bechofy from time to time will add new features and services on their panel. Some features will be given free to all clients whereas certain features will be on additional charges and will be optional for clients.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card">
                                            <div class="card-header py-4" id="heading-2-4" data-toggle="collapse" role="button"
                                                data-target="#collapse-2-5" aria-expanded="false" aria-controls="collapse-2-3">
                                                <h6 class="mb-0"><span class="ti-wallet mr-3"></span>What payment Gateway will we use?

                                                </h6>
                                            </div>
                                            <div id="collapse-2-5" class="collapse" aria-labelledby="heading-2-3"
                                                data-parent="#accordion-2">
                                                <div class="card-body">
                                                    <p>Bechofy will be using the payment gateway with their partners PayU.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card">
                                            <div class="card-header py-4" id="heading-2-4" data-toggle="collapse" role="button"
                                                data-target="#collapse-2-6" aria-expanded="false" aria-controls="collapse-2-3">
                                                <h6 class="mb-0"><span class="ti-credit-card mr-3"></span>What are the payment gateway transactions charges?


                                                </h6>
                                            </div>
                                            <div id="collapse-2-6" class="collapse" aria-labelledby="heading-2-3"
                                                data-parent="#accordion-2">
                                                <div class="card-body">
                                                    <p>Payment gateway Transaction charges will be dependent on the plan or deal that is made individually with the client based on the volume of transactions.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card">
                                            <div class="card-header py-4" id="heading-2-4" data-toggle="collapse" role="button"
                                                data-target="#collapse-2-7" aria-expanded="false" aria-controls="collapse-2-3">
                                                <h6 class="mb-0"><span class="ti-widget mr-3"></span>What is the Logistic Support you get when you take a Bechofy Plan?



                                                </h6>
                                            </div>
                                            <div id="collapse-2-7" class="collapse" aria-labelledby="heading-2-3"
                                                data-parent="#accordion-2">
                                                <div class="card-body">
                                                    <p>Bechofy helps you to get tie-ups with Logistic companies to enable you to have your products reach your clients quickly and safely. Integrations to these logistic platforms can also be enabled with Bechofy.</p>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="card">
                                            <div class="card-header py-4" id="heading-2-4" data-toggle="collapse" role="button"
                                                data-target="#collapse-2-7" aria-expanded="false" aria-controls="collapse-2-3">
                                                <h6 class="mb-0"><span class="ti-world mr-3"></span>In what countries can I use the Bechofy Platform?




                                                </h6>
                                            </div>
                                            <div id="collapse-2-7" class="collapse" aria-labelledby="heading-2-3"
                                                data-parent="#accordion-2">
                                                <div class="card-body">
                                                    <p>Currently we are open to customers in India. Very soon we shall open our platform to use across the world.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card">
                                            <div class="card-header py-4" id="heading-2-4" data-toggle="collapse" role="button"
                                                data-target="#collapse-2-7" aria-expanded="false" aria-controls="collapse-2-3">
                                                <h6 class="mb-0"><span class="ti-money mr-3"></span>What currencies can be used on the website to sell your products?





                                                </h6>
                                            </div>
                                            <div id="collapse-2-7" class="collapse" aria-labelledby="heading-2-3"
                                                data-parent="#accordion-2">
                                                <div class="card-body">
                                                    <p>You can sell your products in Indian Rupees Only. At the moment we do not accept any other currency. Our payment gateways accept only Indian Rupees.

                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>
                </div>
                <Footer />

            </>
        )
    }
}
export default FAQ;